import { useContext } from 'react';
import axios from "axios";
import { setmaster } from "../store/actions/dataActions";
import { setAccount, setActivities, setUser, setUsers } from "../store/actions/userActions";
import { setStats } from "../store/actions/statsActions";
import { gtag } from 'ga-gtag';
import { UAParser } from 'ua-parser-js';
import axiosRetry from 'axios-retry';

// Global variables
var token = localStorage.getItem('token')
var plan_type = localStorage.getItem('plan_type')
const apiUrl = process.env.REACT_APP_API_ENDPOINT
const default_examspace_id = ""
axiosRetry(axios, { 
  retries: 2,
  retryDelay: (retryCount) => {
    return retryCount * 3000; // Waits 1 second, 2 seconds, 3 seconds, etc.
  }
});




// API validate for token
var excluded_paths_from_interceptor = ["/", "/login", "/signup", "/feedback", "/help", "/message"]
axios.interceptors.response.use(function (response) {
  let path = window.location.pathname;
  if (response.data.status === false && response.data.message === "Token is invalid!" && excluded_paths_from_interceptor.indexOf(path) < 0) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload(false);
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});


export const getUserAuthToken = () => {
  return token;
};


export const getApiUrl = () => {
  return apiUrl;
};


export const CreateGtagEvent = (event, payload) => {
  gtag('event', event, payload);
}


// get user metadata
export function getUserMetadata() {
  return new Promise((resolve, reject) => {
  try{
      let responseData = {};
      // extract metadata from user agent string
      const parser = new UAParser(navigator.userAgent);
      let parserResults = parser.getResult();
      // browser
      try {
        responseData["browser"] = parserResults["browser"]["name"] == undefined ? "" : parserResults["browser"]["name"]
      } catch (e) {
        responseData["browser"] = null
      }
      // os
      try {
        responseData["os"] = parserResults["os"]["name"] == undefined ? "" : parserResults["os"]["name"]
      } catch (e) {
        responseData["os"] = null
      }
      // user_agent
      try {
        responseData["user_agent"] = parserResults["ua"] == undefined ? "" : parserResults["ua"]
      } catch (e) {
        responseData["user_agent"] = null
      }
      // device_type
      try {
        responseData["device_type"] = parserResults["device"]["type"] == undefined ? "" : parserResults["device"]["type"]
      } catch (e) {
        responseData["device_type"] = null
      }      
      // get Geo location data
      fetch("https://ipapi.co/json/")
        .then((res) => res.json())
        .then((res) => {
          responseData["city"] = res["city"]
          responseData["country"] = res["country_name"]
          responseData["ip_address"] = res["ip"]
          resolve(responseData);
        }).catch((error)=>{
          console.error(error);
          resolve(responseData);
        })
  } catch(error){
    resolve({});
  }
  })
};



export const GetUserInfoAPI = async () => {
  try{
    var response = await axios({
      method: "GET",
      url: `${apiUrl}/user-info`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    // set values to local storage
    localStorage.setItem("user_info", JSON.stringify(response.data.data));
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};

export const SendDendeEvents = async (body) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/dende-events',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: body,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};


export const getLibraryAPI = async (examspace_id) => {
  try{
    const requestOptions = {
      method: "GET",
      url:  `${apiUrl}/library?default_examspace_id=${examspace_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const getExamSpaceDashboard = async (examspace_id) => {
  try{
    const requestOptions = {
      method: "GET",
      url: `${apiUrl}/examspace/dashboard?default_examspace_id=${examspace_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const getAllExamSpaces = async () => {
  try{
    const requestOptions = {
      method: "GET",
      url: apiUrl + '/examspace',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error) {
    return {"status": false, "data": [], "message": "API error, can you please reload the page."};
  }
};

export const postExamSpaceFile = async (body) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/examspace',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: body,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const updateExamSpaceTitle = async (requestData) => {
  try{
    const requestOptions = {
      method: "PUT",
      url: apiUrl + '/examspace',
      data: requestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const setDefaultExamSpace = async (requestData) => {
  try{
    const requestOptions = {
      method: "PUT",
      url: apiUrl + '/user-fe-analytics',
      data: requestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const DeleteExamSpace = async (requestData) => {
  try{
    const requestOptions = {
      method: "DELETE",
      url: apiUrl + '/examspace?id=' + requestData,
      data: requestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const ImportResourceFile = async (requestData) => {
  try{
    const requestOptions = {
      method: "PUT",
      url: apiUrl + '/examspace/resource',
      data: requestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }

};

export const DeleteResource = async (examspace_id, resource_id) => {
  try{
    const requestOptions = {
      method: "DELETE",
      url: apiUrl + `/examspace/resource?examspace_id=${examspace_id}&resource_id=${resource_id}`,
      // data: requestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const getTopicsListAPI = async (body) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/topics-list',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: body,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};

export const conceptRenameAPI = async (body) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/concept/rename',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: body,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};

export const resourceRenameAPI = async (body) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/resource/rename',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: body,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};


export const paypalInfo = async () => {
  try{
    const requestOptions = {
      method: "GET",
      url: apiUrl + '/paypal-info',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};


export const cancelSubscription = async () => {
  try{
    const requestOptions = {
      method: "DELETE",
      url: apiUrl + '/cancel-subscription',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const resumeSubscription = async () => {
  try{
    const requestOptions = {
      method: "GET",
      url: apiUrl + '/resume-subscription',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};
export const scheduleSubscriptionAPI = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/schedule-subscription',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: requestData,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const getPaymentHistory = async () => {
  try{
    const requestOptions = {
      method: "GET",
      url: apiUrl + '/payment-history',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const getAllConcepts = async (examspace_id) => {
  try{
    const requestOptions = {
      method: "GET",
      url: `${apiUrl}/concepts?default_examspace_id=${examspace_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const getNewTopicsAPI = async (examspace_id) => {
  try{
    const requestOptions = {
      method: "GET",
      url: `${apiUrl}/new-topics?default_examspace_id=${examspace_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const getConceptQuestionAPI = async ({topicId="", resourceId=""}) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/concept-questions',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        "topic_id": topicId,
        "resource_id": resourceId,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const getQuestionListAPI = async (requestJson) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/question-list',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: requestJson,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const getConceptInfoAPI = async ({resourceId="", topicId=""}) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/concept-info',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        "topic_id": topicId,
        "resource_id": resourceId,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const getAllReviewQuestions = async (examspace_id) => {
  try{
    const requestOptions = {
      method: "GET",
      url: apiUrl + `/get-review-questions?default_examspace_id=${examspace_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const updateUserProfile = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/update-profile',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: requestData,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const paypalUIWebhook = (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/paypal-ui-webhook',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: requestData,
    };
    var response = axios(requestOptions);
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const getQuestionUtility = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/get-question',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: requestData,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error) {
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};
export const getThreeAnsweredQuestion = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/three-question-answered',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: requestData,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};
export const forgetPassword = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/forget-password',
      headers: {
        "Content-Type": "application/json",
      },
      data: requestData,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};

export const resetPassword = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/reset-password',
      headers: {
        "Content-Type": "application/json",
      },
      data: requestData,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};
export const uploadSelectedFile = async (file) => {
  try{
    let formData = new FormData();
    formData.append('file', file)
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/upload-file',
      headers: {
        "Content-Type": "form-data",
        Authorization: "Bearer " + token,
      },
      data: formData,
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};
export const listAllResources = async () => {
  try{
    const requestOptions = {
      method: "GET",
      url: apiUrl + '/all-resource',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};
export const listAllUnskippedResources = async (examspace_id) => {
  try{
    const requestOptions = {
      method: "GET",
      url:  `${apiUrl}/unskipped-resource?default_examspace_id=${examspace_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};

export const getAllTopicList = async (examspace_id) => {
  try{
    const requestOptions = {
      method: "GET",
      url: `${apiUrl}/home-topic-list?default_examspace_id=${examspace_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }
};

export const loginNew = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + "/login",
      data: requestData,
      headers: {
        "Content-Type": "application/json",
      },
    };
    var response = await axios(requestOptions);
    if (response.data.status) {
      token = response.data.data.token;
      let userId = response.data.data.id;
      let data = response.data.data;
      let plantype = response.data.data.plan_type;
      let planenddate = response.data.data.plan_end_date;
      localStorage.setItem('token', token)
      localStorage.setItem('userId', userId)
      localStorage.setItem('plan_type', plantype)
      localStorage.setItem('plan_end_date', planenddate)
      localStorage.setItem('resource_count', response.data.data.resource_count)
      localStorage.setItem('user', JSON.stringify(data))
      if (response.data.data.isanswered_first_question === true) {
        localStorage.setItem('eQuestionFlag', true)
      } else {
        localStorage.setItem('eQuestionFlag', false)
      }
    }
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }

};

export const handleSSOLoginData = async (requestData) => {
  try{
    let response = requestData;
    if (response.status) {
      token = response.data.token;
      let userId = response.data.id;
      let data = response.data;
      let plantype = response.data.plan_type;
      let planenddate = response.data.plan_end_date;
      localStorage.setItem('plan_type', plantype)
      localStorage.setItem('plan_end_date', planenddate)
      localStorage.setItem('token', token)
      localStorage.setItem('userId', userId)
      localStorage.setItem('user', JSON.stringify(data))
      localStorage.setItem('resource_count', response.data.resource_count)
    }
    return response;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};


export const createUserRequest = async (requestData) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + "/signup",
      data: requestData.data,
      headers: {
        "Content-Type": "application/json",
      },
    };
    var response = await axios(requestOptions);
    if (response.data.status) {
      token = response.data.data.token;
      let userId = response.data.data.id;
      let data = response.data.data;
      let plantype = response.data.data.plantype;
      let planenddate = response.data.data.plan_end_date;
      localStorage.setItem('token', token)
      localStorage.setItem('userId', userId)
      localStorage.setItem('plan_type', plantype)
      localStorage.setItem('plan_end_date', planenddate)
      localStorage.setItem('user', JSON.stringify(data))
  
      if ("dispatch" in requestData.data) {
        requestData.data.dispatch(setUser({ token, userId, data }));
      }
    }
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};
export const ExamSpaceUploadResource = async (fileBlob, default_examspace_id) => {
  try{
    let data = new FormData();
    data.append('file', fileBlob)
    data.append('default_examspace_id', default_examspace_id)
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/examspace/resource',
      data: data,
      headers: {
        "Content-Type": "form-data",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }  
};

export const getFlashcardAPI = async (resource_id) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/flashcard',
      data: {
        "resource_id": resource_id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }    
};

export const flashcardAnalyticsAPI = async (request_json) => {
  try{
    const requestOptions = {
      method: "POST",
      url: apiUrl + '/flashcard/analytics',
      data: request_json,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var response = await axios(requestOptions);
    return response.data;
  } catch(error){
    return {"status": false, "data": [], "message": "API failed to fetch"};
  }    
};


// ########## below APIs are older #############

export const getUserAnalyticsAPI = async () => {
  const requestOptions = {
    method: "GET",
    url: apiUrl + '/user-analytics',
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  return response.data;
};

export const deleteFile = async (s3_file_path) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/delete-file',
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      "s3_file_path": s3_file_path
    },
  };
  var response = await axios(requestOptions);
  return response.data;
};
export const processFileAPI = async (s3_file_info) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/process-file',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    data: s3_file_info
  };
  var response = await axios(requestOptions);
  return response.data;
};
export const deleteResource = async (resource_id) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/delete-resource',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    data: {
      "resource_id": resource_id
    }
  };
  var response = await axios(requestOptions);
  return response.data;
};



export const updateuser = async (requestData) => {
  const id = localStorage.getItem('userId')
  const requestOptions = {
    method: "PUT",
    url: apiUrl + '/users/' + id,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  return response.data;
};

export const getAllUsers = async (requestData) => {
  const requestOptions = {
    method: "GET",
    url: apiUrl + '/users',
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  if (response.data.status) {
    if ("dispatch" in requestData) {
      requestData.dispatch(setUsers(response.data.data));
    }
    return response.data.data;
  } else {
    if ("dispatch" in requestData) {
      requestData.dispatch(setUsers([]));
    }
    return [];
  }
};

export const getAccount = async (requestData) => {
  const requestOptions = {
    method: "GET",
    url: apiUrl + '/users/' + requestData.id,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  if (response.data.status) {
    if ("dispatch" in requestData) {
      requestData.dispatch(setAccount(response.data.data));
    }
    return response.data.data;
  } else {
    if ("dispatch" in requestData) {
      requestData.dispatch(setAccount(null));
    }
    return [];
  }
};

export const getActivity = async (requestData) => {
  const requestOptions = {
    method: "GET",
    url: apiUrl + '/activities/user/' + requestData.id,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  if (response.data.status) {
    if ("dispatch" in requestData) {
      requestData.dispatch(setActivities(response.data.data));
    }
    return response.data.data;
  } else {
    if ("dispatch" in requestData) {
      requestData.dispatch(setActivities(null));
    }
    return [];
  }
};

export const deleteAccount = async (requestData) => {
  const requestOptions = {
    method: "DELETE",
    url: apiUrl + '/users/' + requestData.id,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  await axios(requestOptions);
  getAllUsers({ dispatch: requestData.dispatch });
};

export const uploadFile = async (requestData) => {
  let data = new FormData();
  data.append('file', requestData.data.file)
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/upload-resource',
    data: data,
    headers: {
      "Content-Type": "form-data",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  if (response.data.status) {
    await masterRequest({ dispatch: requestData.dispatch });
  }
  return response.data;
};



export const uploadResource = async (requestData) => {
  let data = new FormData();
  data.append('file', requestData.data.file)
  data.append('examspace_id', requestData.data.examspace_id)
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/upload-resource',
    data: data,
    headers: {
      "Content-Type": "form-data",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  return response.data;
};



export const deleteContentById = async (requestData) => {
  const requestOptions = {
    method: "DELETE",
    url: apiUrl + '/contents/' + requestData.data.id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  await masterRequest({ dispatch: requestData.dispatch });
  return response.data;
};

export const updateContentById = async (requestData) => {
  const requestOptions = {
    method: "PUT",
    url: apiUrl + '/contents/' + requestData.data.id,
    data: requestData.data.note,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  await masterRequest({ dispatch: requestData.dispatch });
  return response.data;
};

export const updateURLContentById = async (requestData) => {
  const requestOptions = {
    method: "PUT",
    url: apiUrl + '/contents/' + requestData.data.id,
    data: requestData.data.url,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  await masterRequest({ dispatch: requestData.dispatch });
  return response.data;
};


export const reviewFlashcard = async (requestData) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/solutions',
    data: requestData.data,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  // masterRequest({dispatch: requestData.dispatch});
  return response.data;
};

export const reviewMCQ = async (requestData) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/solutions',
    data: requestData.data,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  // masterRequest({dispatch: requestData.dispatch});
  return response.data;
};

export const generate = async (requestData) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/generate_questions_new',
    data: requestData.data,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  if ("dispatch" in requestData) {
    masterRequest({ dispatch: requestData.dispatch });
  }
  return response.data;
}

export const generateFirst = async (requestData) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/generate_questions_first',
    data: requestData.data,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  if ("dispatch" in requestData) {
    masterRequest({ dispatch: requestData.dispatch });
  }
  generate(requestData);
  return response.data;
}

export const masterRequest = async (requestData) => {

  const requestOptions = {
    method: "GET",
    url: apiUrl + '/master',
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);

  if ("dispatch" in requestData) {
    requestData.dispatch(setmaster(response.data));
  }

  return response.data;
};


export const masterQuestionsRequest = async (requestData) => {

  const requestOptions = {
    method: "GET",
    url: apiUrl + `/master_questions?lastId=${requestData.lastId}`,
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);

  if ("dispatch" in requestData) {
    requestData.dispatch(setmaster(response.data));
  }

  return response.data;
};

export const statsRequest = async (requestData) => {

  const requestOptions = {
    method: "GET",
    url: apiUrl + '/stats',
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);

  if ("dispatch" in requestData) {
    requestData.dispatch(setStats(response.data));
  }

  return response.data;
};

export const createContent = async (requestData) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/contents',
    data: requestData.data,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var response = await axios(requestOptions);
  if (response.data.status) {
    masterRequest({ dispatch: requestData.dispatch });
  }
  return response.data;
};

export const evaluateAnswer = async (requestData) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/evaluate_answers_new',
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var raw = await axios(requestOptions)
  let response = raw.data
  return response;
};

export const addTimeLog = async (requestData) => {
  const requestOptions = {
    method: "POST",
    url: apiUrl + '/timelogs',
    data: requestData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var raw = await axios(requestOptions)
  let response = raw.data
  return response;
};

