import { useState, useEffect, useRef, useLayoutEffect, useContext } from "react";
// import "./ConceptNew.scss";
import style from "./Concept.module.scss";
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import lockIcon from "../../assets/lock.png";
// import padlockIcon from "../../assets/padlock.png";
import padlockIcon from "../../assets/lock.png";
import {
  getApiUrl,
  getUserAuthToken,
  getAllConcepts,
  listAllUnskippedResources,
  conceptRenameAPI,
  resourceRenameAPI,
  ExamSpaceUploadResource,
  GetUserInfoAPI,
  getAllExamSpaces,
  getNewTopicsAPI,
} from "../../helpers/requests";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import threeDotIcon from "../../assets/three-dot-menu-icon.png"
import closeIcon from "../../assets/small-close.png"
import smallSVG from "../../assets/loading-svg-orange.svg"
import uploadIcon from "../../assets/upload-white-icon.png"
import loadingsmall from "../../assets/loading-svg-orange.svg"
// import downArrowIcon from "../../assets/new/down-arrow.svg"
import {ReactComponent as EyeIcon} from "../../assets/new/eye.svg"
import {ReactComponent as PlayIcon} from "../../assets/new/play.svg"
import rocketIcon from "../../assets/new/rocket.svg"
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import ExamSpacePopup from "../../Components/ExamSpacePopup/ExamSpacePopup";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';
import { ReactComponent as PlayIconWithCircle} from "../../assets/new/playIconWithCircle.svg"
import { ReactComponent as SearchIcon} from "../../assets/new/search-black-icon.svg"
import { ReactComponent as FolderIcon} from "../../assets/new/folder.svg"
import { ReactComponent as LoadingIcon} from "../../assets/loading-svg.svg"
import { ReactComponent as DownArrowIcon} from "../../assets/new/down-arrow.svg"
import { ReactComponent as TopicIcon} from "../../assets/new/topic-icon.svg"
import { ReactComponent as BulbIcon} from "../../assets/new/bulb-icon.svg"
import { set } from "date-fns";

export default function Concept() {
  // page initialize
  const [resource_available, set_resource_available] = useState(true);
  const [topic_available, set_topic_available] = useState(true);
  
  const [data_available, set_data_available] = useState(true);
  const [question_available, set_question_available] = useState(true);
  // check for resource topic and topic question is genertated
  const isAllResourceTopicGenerated = useRef(false);
  const isAllTopicQuestionGenerated = useRef(false);
  
  
  
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");
  
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const navigate = useNavigate();
  const [conceptdata, setConceptData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [newTopicsData, setNewTopicsData] = useState([]);
  const [resourceid, setResourceId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tempInterval, setTempInterval] = useState(0);
  const [displayLoadingUI, setDisplayLoadingUI] = useState(false);
  const [plan_type, set_plan_type] = useState(localStorage.getItem("plan_type"));
  const [question_count, set_question_count] = useState(null);
  const [topic_gif_visible, set_topic_gif_visible] = useState(false);
  const [concept_json_data_backup, set_concept_json_data_backup] = useState(false);
  const [showExamspacePopup, setShowExamspacePopup] = useState("false");
  const [examspaceData, setExamspaceData] = useState([]);
  const [defaultExamspace, setDefaultExamspace] = useState({});
  const [chapters_topics_btn, set_chapters_topics_btn] = useState("chapters");
  const chapters_topics_btn_value = useRef("chapters");
  const [window_width, set_window_width] = useState(window.innerWidth);
  
  const mobileContextMenuVisibility = useRef(null);
  const mobileResponsiveTopicId = useRef(null);
  const continuousPollIntervalIdList = useRef([]);
  const conceptQuestionGenerationIntervalId = useRef(null);
  const conceptQuestionListIntervalId = useRef(null);
  const selectedResourceId = useRef("");
  const editConceptTitleOriginalInfo = useRef(null);
  const modifiedConceptTitle = useRef(null);
  const editResourceTitleOriginalInfo = useRef(null);
  const modifiedResourceTitle = useRef(null);
  const fileUploadRef = useRef(null);
  const listOfResourcesOpened = useRef([]);
  const searchBoxInputElement = useRef(null);
  const chaptersTopicsBtnRef = useRef(null);
  let questionStatusNameMapping = {
    "correct": "Correct",
    "wrong": "Wrong",
    "unanswered": "New",
    "reread": "Refresh"
  }
  
  useEffect(()=>{
    localStorage.setItem("defaultTopicType", "chapters")
    setDisplayLoadingUI(true)
    proxyCallGetDefaultExamspaceIdUtility()
    // Listen mouse click event
    // 1) The mouse click event is used to rename the concept title and resource title in the chapter view
    // 2) The mouse click event is used to close the search box in mobile view
    const handleClick = (event) => {
      if(editConceptTitleOriginalInfo.current !== null){
        if(event.target.className !== style["resource-topic-card-topic-title"]){
          if(modifiedConceptTitle.current === editConceptTitleOriginalInfo.current["concept-title"]){
            editConceptTitleOriginalInfo.current = null;
            modifiedConceptTitle.current = null;
            resetConceptTitle();
          } else {
            handleConceptRename();
          }
        }
      }
      if(editResourceTitleOriginalInfo.current !== null){
        if(event.target.className !== style["resource-card-title"]){
          if(modifiedResourceTitle.current === editResourceTitleOriginalInfo.current["resource-title"]){
            editResourceTitleOriginalInfo.current = null;
            modifiedResourceTitle.current = null;
            resetResourceTitle();
          } else {
            handleResourceRename();
          }
        }
      }
      if(window_width < 1000){
        if(!event.target.closest(`.${style["search-container"]}`)){     
          searchBoxInputElement.current.style.display = "none";
        }
        if(!event.target.closest(`.${style["chapters-topics-btn-container"]}`)){
          chaptersTopicsBtnRef.current.style.display = "none";
        }
      }
    };
    document.addEventListener('click', handleClick);
    // Listen window resize event
    const handleResize = () => {
      set_window_width(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    window.scrollTo(0, 0);
    return()=>{
      // remove mouse click event listener
      document.removeEventListener('click', handleClick);
      // remove window resize event listener
      window.removeEventListener("resize", handleResize);
      // clear all interval
      continuousPollIntervalIdList.current.forEach((intervalId)=>{
        clearInterval(intervalId);
      });        
      // clear local storage
      localStorage.removeItem("selectedResourceIdArray");
    }
  },[sharedUserInfo]);
  
  async function proxyCallGetDefaultExamspaceIdUtility(){
    let response = await getDefaultExamspaceIdUtility();
    // get previous selected topic type by user
    if(localStorage.getItem("defaultTopicType") !== null || localStorage.getItem("defaultTopicType") !== undefined){
      if(localStorage.getItem("defaultTopicType") === "chapters"){
        set_chapters_topics_btn("chapters")
        chapters_topics_btn_value.current = "chapters"
      } else if(localStorage.getItem("defaultTopicType") === "topics"){
        set_chapters_topics_btn("topics")
        chapters_topics_btn_value.current = "topics"
      }
    }
    if(chapters_topics_btn_value.current === "chapters"){
      chaptersDataInitialCheck()
    } else {
      TopicsDataInitialCheck()
    }
  }
  
  function closeExamSpacePopup() {
    setShowExamspacePopup("false")
  }

  function clearPollFunctionIntervals(){
    console.log('clearPollFunctionIntervals called - continuousPollIntervalIdList.current:', continuousPollIntervalIdList.current)
    continuousPollIntervalIdList.current.forEach((intervalId)=>{
      console.log('switchTopicsType - clearing interval id:', intervalId)
      clearInterval(intervalId);
      continuousPollIntervalIdList.current = continuousPollIntervalIdList.current.filter((id)=>id !== intervalId);
    });
  }
  
  async function getDefaultExamspaceIdUtility(){
    return new Promise( async(resolve, reject) => {
      let default_examspace_id = "";
      if( Object.keys(sharedUserInfo).length > 0){
        default_examspace_id = sharedUserInfo.default_examspace_id;
        global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
        resolve(default_examspace_id);
      } 
      else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
          if(getResponse.data.length > 0){
            if(Object.keys(getResponse.data[0]).length > 0){
              setSharedUserInfo(getResponse.data[0]);
              default_examspace_id = getResponse.data[0]["default_examspace_id"];
              global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
              resolve(default_examspace_id);
            }
          }
        }
      }
      
    });
  } 
  
  
  async function chaptersDataInitialCheck(){
    console.log('chaptersDataInitialCheck called')
    try{
      let examspace_api_response = await getAllExamSpaces();
      setDisplayLoadingUI(false)
      if (examspace_api_response.data && examspace_api_response.data.length > 0) {

        setExamspaceData(examspace_api_response.data)
        let defaultExamspaceArray = examspace_api_response.data.filter(obj => obj.is_default === true);
        if(defaultExamspaceArray.length > 0){
          setDefaultExamspace(defaultExamspaceArray[0])
        }

        if (defaultExamspaceArray.length > 0 &&  defaultExamspaceArray[0]["resources"].length > 0) {
          set_resource_available(true);
          
          let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
          if(conceptResponse.status){
            if(conceptResponse.data.length > 0){
              set_topic_available(true);
              setConceptData(conceptResponse.data);
              setFilterData(conceptResponse.data);
              setTimeout(()=>{
                if(listOfResourcesOpened.current.length <= 0){
                  if(conceptResponse.data[0].topic_list.length > 0){
                    listOfResourcesOpened.current.push(conceptResponse.data[0].resource_id)
                  }
                  openFileContent()
                } else{
                  openFileContent()
                }
              },100)               
              
              const isResourceTopicGenerated = conceptResponse.data.every(resource => resource.topic_list.length > 0);
              const isTopicQuestionGenerated = conceptResponse.data.every(resource =>
                resource.topic_list
                .filter(topic => topic.status === "pending")
                .every(topic => topic.total_question_count > 0)
              );              
              isAllResourceTopicGenerated.current = isResourceTopicGenerated;
              isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;
              
              if(isAllResourceTopicGenerated.current === true && isAllTopicQuestionGenerated.current === true){
                clearPollFunctionIntervals()
              } else {
                if(continuousPollIntervalIdList.current.length <= 0){
                  continuousChaptersPollFucntion();
                }
              }
            } else {
              set_topic_available(false);
              if(continuousPollIntervalIdList.current.length <= 0){
                continuousChaptersPollFucntion();
              }                            
            }                     
          } else {
            set_topic_available(false);
            toast.error(conceptResponse.message, {
              position: toast.POSITION.TOP_CENTER,
            });                        
          }
        } else {
          set_resource_available(false);
          set_topic_available(false);
        } 
      } else {
        toast.error(examspace_api_response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      
    } catch (error) {
      console.error("concept", error)
    }
  }
  
  function continuousChaptersPollFucntion(){
    console.log('continuousChaptersPollFucntion called')
    let timeDuration  = 0;
    let tempIntervalId = setInterval(async ()=>{
      timeDuration += 20;
      if(timeDuration >= 600){
        clearPollFunctionIntervals()               
      }
      console.log('continuousChaptersPollFucntion - calling getChaptersDataOnce')
      getChaptersDataOnce();
    }, 20000);
    continuousPollIntervalIdList.current.push(tempIntervalId);
  }

  async function getChaptersDataOnce(){
    console.log('getChaptersDataOnce called')
    let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
    setDisplayLoadingUI(false)
    if(conceptResponse.status){
      if(conceptResponse.data.length > 0){
        set_topic_available(true);
        setConceptData(conceptResponse.data);
        setFilterData(conceptResponse.data);
        setTimeout(()=>{
          if(listOfResourcesOpened.current.length <= 0){
            listOfResourcesOpened.current.push(conceptResponse.data[0].resource_id)
            openFileContent()
          } else{
            openFileContent()
          }
        },100)  
        
        const isResourceTopicGenerated = conceptResponse.data.every(resource => resource.topic_list.length > 0);
        const isTopicQuestionGenerated = conceptResponse.data.every(resource =>
          resource.topic_list
          .filter(topic => topic.status === "pending")
          .every(topic => topic.total_question_count > 0)
        );        
        isAllResourceTopicGenerated.current = isResourceTopicGenerated;
        isAllTopicQuestionGenerated.current = isTopicQuestionGenerated;
        if(isAllResourceTopicGenerated.current === true && isAllTopicQuestionGenerated.current === true){
          clearPollFunctionIntervals()
        }   
        
      } else {
        set_topic_available(false);
      }       
    } else {
      set_topic_available(false);
      toast.error(conceptResponse.message, {
        position: toast.POSITION.TOP_CENTER,
      });                        
    }        
  }  

  async function TopicsDataInitialCheck(){
    console.log('TopicsDataInitialCheck called')
    try{
      let examspace_api_response = await getAllExamSpaces();
      setDisplayLoadingUI(false)
      if (examspace_api_response.data && examspace_api_response.data.length > 0) {
        setExamspaceData(examspace_api_response.data)
        let defaultExamspaceArray = examspace_api_response.data.filter(obj => obj.is_default === true);
        if(defaultExamspaceArray.length > 0){
          setDefaultExamspace(defaultExamspaceArray[0])
        }
  
        if (defaultExamspaceArray.length > 0 && defaultExamspaceArray[0]["resources"].length > 0) {
          set_resource_available(true);
  
          let newTopicsResponse = await getNewTopicsAPI(global_default_examspace_id.current);
          if(newTopicsResponse.data && newTopicsResponse.status){
            if(newTopicsResponse.data.length > 0){
              console.log('TopicsDataInitialCheck questions available')
              set_topic_available(true);
              setNewTopicsData(newTopicsResponse.data);
              setFilterData(newTopicsResponse.data);    

              let doesAllTopicsUpdated = newTopicsResponse.data.every(topic => topic.is_update_completed === true);
              if(doesAllTopicsUpdated){
                clearPollFunctionIntervals()
              } else {
                if(continuousPollIntervalIdList.current.length <= 0){
                  continuousTopicsDataPoll();
                }
              }

            } else {
              console.log('TopicsDataInitialCheck - questions not available')
              set_topic_available(false);
              console.log('TopicsDataInitialCheck - continuousPollIntervalIdList.current: ', continuousPollIntervalIdList.current)
              if(continuousPollIntervalIdList.current.length <= 0){
                console.log('TopicsDataInitialCheck - calling continuousTopicsDataPoll')
                continuousTopicsDataPoll();
              }                            
            }                     
          } else {
            console.log('TopicsDataInitialCheck - error occured')
            set_topic_available(false);
            toast.error(newTopicsResponse.message, {
              position: toast.POSITION.TOP_CENTER,
            });                        
          }
        } else {
          set_resource_available(false);
          set_topic_available(false);
        } 
      } else {
        toast.error(examspace_api_response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      
    } catch (error) {
      console.error("concept", error)
    }
  }
  
  function continuousTopicsDataPoll(){
    console.log('continuousTopicsDataPoll called')
    let timeDuration  = 0;
    let tempIntervalId = setInterval(async ()=>{
      timeDuration += 20;
      if(timeDuration >= 600){
        clearPollFunctionIntervals()                 
      }
      console.log('continuousTopicsDataPoll - calling getNewTopicsOnce')
      getNewTopicsOnce();
    }, 20000);
    continuousPollIntervalIdList.current.push(tempIntervalId);        
  }
  
  async function getNewTopicsOnce(){
    console.log('getNewTopicsOnce called')
    let newTopicsResponse = await getNewTopicsAPI(global_default_examspace_id.current);
    if(newTopicsResponse.data && newTopicsResponse.status){
      if(newTopicsResponse.data.length > 0){
        console.log('getNewTopicsOnce - topics available')
        set_topic_available(true);
        setNewTopicsData(newTopicsResponse.data);
        setFilterData(newTopicsResponse.data);

        let doesAllTopicsUpdated = newTopicsResponse.data.every(topic => topic.is_update_completed === true);
        if(doesAllTopicsUpdated){
          clearPollFunctionIntervals()
        }

      } else {
        console.log('getNewTopicsOnce - topics not available')
        set_topic_available(false);
      }       
    } else {
      console.log('getNewTopicsOnce - error occured')
      set_topic_available(false);
      toast.error(newTopicsResponse.message, {
        position: toast.POSITION.TOP_CENTER,
      });                        
    }        
  } 

  function openFileContent(){
    listOfResourcesOpened.current.forEach((resourceId)=>{
      topicListOpenOnly(resourceId)
    });
  }
  
  function topicListOpenOnly(res_id){
    let topic_list_element = document.getElementById(`resource-card-topic-list-${res_id}`);
    let res_triangle_element = document.getElementById(`resource-card-open-handle-${res_id}`);
    try {
      if(topic_list_element && topic_list_element){
        if(topic_list_element.style.display !== null | topic_list_element.style.display !== undefined){
          topic_list_element.style.display = "block";
          res_triangle_element.style.rotate = "180deg";
        }
      }
    } catch(e){
      console.error(e)
    }
  }
  
  async function handleConceptRename(){
    modifiedConceptTitle.current = modifiedConceptTitle.current.trim();
    if(modifiedConceptTitle.current.toString().length >= 3){
      let body = {
        "concept_id": editConceptTitleOriginalInfo.current["concept-id"],
        "concept_title": modifiedConceptTitle.current
      }
      let apiResponse = await conceptRenameAPI(body);
      if(apiResponse){
        editConceptTitleOriginalInfo.current = null;
        modifiedConceptTitle.current = null;
        setConceptData(prevData => [...prevData, {}].slice(0, -1))
        getChaptersDataOnce()
        resetConceptTitle()
      }
    } else {
      toast.error("Concept title must be atleast 3 characters", {
        position: toast.POSITION.TOP_CENTER,
      });
      setConceptData([])
      getChaptersDataOnce()
      editConceptTitleOriginalInfo.current = null;
      modifiedConceptTitle.current = null;
      setTimeout(()=>{
        openFileContent()
        // let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
        // if(selectedResourceId != null && selectedResourceId != undefined){
        //     openTopicList(selectedResourceId)
        // }
      },1000)
    }
  }
  
  async function handleResourceRename(){
    modifiedResourceTitle.current = modifiedResourceTitle.current.trim();
    if(modifiedResourceTitle.current.toString().length >= 3){
      let body = {
        "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
        "resource_title": modifiedResourceTitle.current
      }
      let apiResponse = await resourceRenameAPI(body);
      if(apiResponse){
        editResourceTitleOriginalInfo.current = null;
        modifiedResourceTitle.current = null;
        setConceptData(prevData => [...prevData, {}].slice(0, -1))
        getChaptersDataOnce()
        resetResourceTitle()
      }
    } else {
      toast.error("File title must be atleast 3 characters", {
        position: toast.POSITION.TOP_CENTER,
      });        
      setConceptData([])
      getChaptersDataOnce()
      editResourceTitleOriginalInfo.current = null;
      modifiedResourceTitle.current = null;
      setTimeout(()=>{
        openFileContent()
        // let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
        // if(selectedResourceId != null && selectedResourceId != undefined){
        //     openTopicList(selectedResourceId)
        // }
      },1000)        
    }
  }
  
  const handleSearch = (event) => {
    clearPollFunctionIntervals()
    let searchKeyword = event.target.value;
    console.log("00000000000000000:", searchKeyword)
    setSearchText(searchKeyword);
    try {
      if(searchKeyword === ""){
        if(chapters_topics_btn_value.current === "chapters"){
          getChaptersDataOnce();
        } else if (chapters_topics_btn_value.current === "topics"){
          TopicsDataInitialCheck();
        }
      } else {
        let filteredResourceData = []
        if(filterdata.length > 0){
          if(chapters_topics_btn_value.current === "chapters"){
            filterdata.forEach((resource) => {
              let filtered_topic_list = []
              console.log("1111111111", filtered_topic_list)
              resource.topic_list.forEach((topic) => {
                if(topic.name.toLowerCase().includes(searchKeyword.toLowerCase())){
                  console.log("222222222222", topic)
                  filtered_topic_list.push(topic)
                }
              })
              console.log("3333333333333333", filtered_topic_list)
              if(filtered_topic_list.length > 0){
                filteredResourceData.push({
                  ...resource,
                  topic_list: filtered_topic_list
                })
              }
            })
            console.log("4444444444444444444444444", filteredResourceData)
            setConceptData(filteredResourceData);
            filteredResourceData.forEach((resource)=>{
              topicListOpenOnly(resource.resource_id)
            });
          } else if (chapters_topics_btn_value.current === "topics"){
            filterdata.forEach((topic) => {
              if(topic.name.toLowerCase().includes(searchKeyword.toLowerCase())){
                filteredResourceData.push(topic)
              }
            })
            setNewTopicsData(filteredResourceData);
          }
        }
      }        
    } catch (error) {
      console.error("concept page, error in handleSearch:", error);
    }
  };
  
  function openMobileContextMenu(target){
    
    let menu_element = target.parentNode.parentNode.parentNode.nextSibling;
    
    if(menu_element.style.display === "block"){
      menu_element.style.display = "none";
    } else if(menu_element.style.display === "" || menu_element.style.display === "none"){
      let menu_list = document.getElementsByClassName("concept-responsive-context-menu-container");
      Array.from(menu_list).forEach((element) => {
        element.style.display = "none";
      });
      menu_element.style.display = "block";
    }
  }
  
  function CloseMobileContextMenu(target){
    let menu = target.parentNode.parentNode;
    menu.style.display = "none";
  }
  
  function handleUploadSuccess(response){
    if(chapters_topics_btn_value.current === "chapters"){
      chaptersDataInitialCheck()
    } else {
      TopicsDataInitialCheck()
    }
  }
  
  function triggerFileUpload() {
    console.log(global_default_examspace_id.current)
    fileUploadRef.current.triggerFileInput();
  }
  
  function openTopicList(res_id){
    let topic_list_element = document.getElementById(`resource-card-topic-list-${res_id}`);
    let res_triangle_element = document.getElementById(`resource-card-open-handle-${res_id}`);
    try {
      if(topic_list_element.style.display !== null | topic_list_element.style.display !== undefined){
        if(topic_list_element.style.display === "" || topic_list_element.style.display === "none" ){
          topic_list_element.style.display = "block";
          res_triangle_element.style.rotate = "180deg";
          listOfResourcesOpened.current.push(res_id);
        } else {
          topic_list_element.style.display = "none";
          res_triangle_element.style.rotate = "0deg";
          const newArray = listOfResourcesOpened.current.filter(item => item !== res_id);
          listOfResourcesOpened.current = newArray;
        }
      }
    } catch(e){
      console.error(e)
    }
  }
  
  
  async function handleConceptTitleEdit(event, conceptInfo){
    resetConceptTitle(event);
    if(editConceptTitleOriginalInfo.current !== null){
      modifiedConceptTitle.current = modifiedConceptTitle.current.trim();
      if(modifiedConceptTitle.current !== editConceptTitleOriginalInfo.current["concept-title"]){
        if(modifiedConceptTitle.current.toString().length >= 3){
          let body = {
            "concept_id": editConceptTitleOriginalInfo.current["topic_id"],
            "concept_title": modifiedConceptTitle.current
          }
          let apiResponse = await conceptRenameAPI(body);
          if(apiResponse){
            // new value set
            setConceptData(prevData => [...prevData, {}].slice(0, -1))
            getChaptersDataOnce()
            setConceptTitleInfo(conceptInfo)
          }
        } else {
          toast.error("Concept title must be atleast 3 characters", {
            position: toast.POSITION.TOP_CENTER,
          });          
          setConceptData([])
          getChaptersDataOnce()
          setTimeout(()=>{
            let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
            if(selectedResourceId != null && selectedResourceId != undefined){
              // openTopicList(selectedResourceId)
              editConceptTitleOriginalInfo.current = null;
              modifiedConceptTitle.current = null;
            }
            openFileContent()
          },1000)
        }
      } else {
        setConceptTitleInfo(conceptInfo)
      }
    } else{
      setConceptTitleInfo(conceptInfo)
    }
  }
  
  function setConceptTitleInfo(conceptInfo){
    editConceptTitleOriginalInfo.current = null;
    modifiedConceptTitle.current = null;
    editConceptTitleOriginalInfo.current = {
      "concept-id": conceptInfo.topic_id,
      "concept-title": conceptInfo.name,
    }
    modifiedConceptTitle.current = conceptInfo.name
  }
  function resetConceptTitle(event=undefined){
    // let tags = document.getElementsByClassName(style["resource-topic-card-topic-title"]);
    // Array.from(tags).forEach((element) => {
      //     element.style.boxShadow = "";
    //     element.style.backgroundColor = "#fff";
    //     element.style.padding = "0px";
    // });
    // if(event !== undefined){
    //     event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
    //     event.target.style.backgroundColor = "#fff";
    //     event.target.style.padding = "0px 5px";
    // }
  }
  async function handleResourceTitleEdit(event, resourceInfo){
    resetResourceTitle(event);
    if(editResourceTitleOriginalInfo.current !== null){
      modifiedResourceTitle.current = modifiedResourceTitle.current.trim();
      if(modifiedResourceTitle.current !== editResourceTitleOriginalInfo.current["resource-title"]){
        if(modifiedResourceTitle.current.toString().length >= 3){
          let body = {
            "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
            "resource_title": modifiedResourceTitle.current
          }
          let apiResponse = await resourceRenameAPI(body);
          if(apiResponse){
            // new value set
            setConceptData(prevData => [...prevData, {}].slice(0, -1))
            getChaptersDataOnce()
            setResourceTitleInfo(resourceInfo)
          }
        } else {
          toast.error("File title must be atleast 3 characters", {
            position: toast.POSITION.TOP_CENTER,
          });          
          setConceptData([])
          getChaptersDataOnce()
          setTimeout(()=>{
            let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
            if(selectedResourceId != null && selectedResourceId != undefined){
              // openTopicList(selectedResourceId)
              editResourceTitleOriginalInfo.current = null;
              modifiedResourceTitle.current = null;
            }
            openFileContent()
          },1000)
        }
      } else {
        setResourceTitleInfo(resourceInfo)
      }
    } else{
      setResourceTitleInfo(resourceInfo)
    }
  }
  
  function setResourceTitleInfo(resourceInfo){
    editResourceTitleOriginalInfo.current = null;
    modifiedResourceTitle.current = null;
    editResourceTitleOriginalInfo.current = {
      "resource-id": resourceInfo.resource_id,
      "resource-title": resourceInfo.resource_title,
    }
    modifiedResourceTitle.current = resourceInfo.resource_title
  }
  function resetResourceTitle(event=undefined){
    // let tags = document.getElementsByClassName(style["resource-card-title"]);
    // Array.from(tags).forEach((element) => {
      //     element.style.boxShadow = "";
    //     element.style.backgroundColor = "#fff";
    //     element.style.padding = "0px";
    // });
    // if(event !== undefined){
    //     event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
    //     event.target.style.backgroundColor = "#fff";
    //     event.target.style.padding = "0px 5px";
    // }
  }
  
  const fetchExamSpaceData = async () => {
    let examSpaceResponse = await getAllExamSpaces();
    if (examSpaceResponse.status) {
      if (examSpaceResponse.data.length) {
        setExamspaceData(examSpaceResponse.data)
        const defaultExamspaceDict = examSpaceResponse.data.filter(obj => obj.is_default === true);
        setDefaultExamspace(defaultExamspaceDict[0])
      }
    }
  }
  
  // ui function
  function showLoadingGif(text){
    return(
      <>
      <div className={style["loading-gif-box"]}>
      <img src={smallSVG} alt="loading gif" />
      <p>{text}</p>
      </div>
      </>
    )
  }
  function showProgessBar({answered, unanswered, wrong, reread}){
    if(answered === 0 && unanswered === 0 && wrong === 0 && reread === 0){
      unanswered = 100;
    }
    if(answered > 0 && answered < 1){
      answered = 1;
    }
    if(wrong > 0 && wrong < 1){
      wrong = 1;
    }
    if(reread > 0 && reread < 1){
      reread = 1;
    }
    if(unanswered > 0 && unanswered < 1){
      unanswered = 1;
    }
    return(
      <>
      <div className={style["progress-bar"]}>
      {
        answered > 0 
        ?
        <div 
        style={{width:`${answered}%`, backgroundColor:"var(--light-green)"}}
        className={`${style["progress-bar-child"]}`}>
        <small className={style["global-tooltip"]}>{questionStatusNameMapping["correct"]}</small>
        </div>
        : 
        <></>
      }
      {
        wrong > 0
        ?
        <div 
        style={{width: `${wrong}%`, backgroundColor:"var(--light-orange)"}}
        className={`${style["progress-bar-child"]}`}>
        <small className={style["global-tooltip"]}>{questionStatusNameMapping["wrong"]}</small>
        </div>
        :
        <></>
      }
      {
        reread > 0
        ?
        <div 
        style={{width: `${reread}%`, backgroundColor:"var(--refresh-violet)"}}
        className={`${style["progress-bar-child"]}`}>
        <small className={style["global-tooltip"]}>{questionStatusNameMapping["reread"]}</small>
        </div>
        :
        <></>
      }
      {
        unanswered > 0
        ?
        <div 
        style={{width: `${unanswered}%`, backgroundColor:"var(--pearl-white)"}}
        className={`${style["progress-bar-child"]}`}>
        <small className={style["global-tooltip"]}>{questionStatusNameMapping["unanswered"]}</small>
        </div>
        :
        <></>
      }
      </div>
      </>
    )
  }
  function showGoUnlimitedBtn(text="Go unlimited"){
    return(
      <>
      <div onClick={()=>{navigate("/pushmode")}} className={style["go-unlimited-btn"]}>
      <img src={rocketIcon} alt="rocket icon" />
      <p>{text}</p>
      </div>
      </>
    )
  }
  
  function searchBoxToggleInput(){
    searchBoxInputElement.current.style.display = "block";
  }
  function SearchBoxComponent() { 
    return(
      <>
      <div className={style["search-container"]} onClick={()=>{searchBoxToggleInput()}}>
        <SearchIcon />
        <div className={style["search-input-wrapper"]}>
          <input ref={searchBoxInputElement} onChange={handleSearch}  value={searchText}  type="text" placeholder='Search'/>
        </div>
      </div>            
      </>
    )
  }
  function checkExamspaceNameLength(name){
    if(name.length <= 25){
      return name;
    } else {
      return `${name.substring(0, 25)}...`;
    }
  }
  function ExamspaceSpaceFolderComponent(){ 
    return(
      <>
      <div className={`${style['examspace-folder']}`} onClick={(e)=>{setShowExamspacePopup("examSpace")}}>
      <FolderIcon/>
      {window_width >= 1000 ? <p>{Object.keys(defaultExamspace).length > 0 ? checkExamspaceNameLength(defaultExamspace.title) : "examspace"}</p> : <></>}
      </div>
      <ExamSpacePopup data={examspaceData} popup={showExamspacePopup} close={closeExamSpacePopup} reCallGet={fetchExamSpaceData} />      
      </>
    )
  }
  
  const chapterTopicToggleMobile = () => {
    if(chaptersTopicsBtnRef.current.style.display === "none"){
      chaptersTopicsBtnRef.current.style.display = "flex";
      chaptersTopicsBtnRef.current.style.position = "absolute";
      chaptersTopicsBtnRef.current.style.top = "44px";
      chaptersTopicsBtnRef.current.style.left = "-59px";
    } else {
      chaptersTopicsBtnRef.current.style.display = "none";
    }
  }
  function switchTopicsType({topics_type}){
    console.log('switchTopicsType - clearing interval id list: ', continuousPollIntervalIdList.current)
    set_chapters_topics_btn( (previous_value) => {
      if(previous_value !== topics_type){
        clearPollFunctionIntervals()
        chapters_topics_btn_value.current = topics_type;
        setConceptData([])
        setNewTopicsData([])
        setFilterData([])
        localStorage.setItem("defaultTopicType", topics_type)
        setDisplayLoadingUI(true)
        if(topics_type === "chapters"){
          chaptersDataInitialCheck()
        } else if(topics_type === "topics"){
          console.log('switchTopicsType - calling TopicsDataInitialCheck')
          TopicsDataInitialCheck()
        }
        return topics_type;
      } else {
        return previous_value;
      }
    }
  )
}
function ChapterTopicToggleComponent() { 
  return(
    <>
      <div className={style["chapters-topics-btn-container"]}>
        <div 
          className={style["chapters-topics-mobile-btn"]}
          onClick={chapterTopicToggleMobile}
          style={ window_width <= 1000 ? {display: "flex"} : {display: "none"}}
        >
          {chapters_topics_btn === "chapters" ? <TopicIcon/> : <></>}
          {chapters_topics_btn === "topics" ? <BulbIcon/> : <></>}
        </div>
        <div 
          className={style["chapters-topics-desktop-btn"]} 
          ref={chaptersTopicsBtnRef}
          style={ window_width > 1000 ? {display: "flex"} : {display: "none"}}
        >
          <article
            onClick={()=>{switchTopicsType({topics_type: "chapters"})}}
            className={chapters_topics_btn === "chapters" ? `${style["chapters-topics-desktop-btn-selected"]}` : ""}
          >
            Chapters
          </article>
          <article
            onClick={()=>{switchTopicsType({topics_type: "topics"})}}
            className={chapters_topics_btn === "topics" ? `${style["chapters-topics-desktop-btn-selected"]}` : ""}
          >
            Topics
          </article>
        </div>
      </div>
    </>
  )
}



return (
  <>
  <main className={style["concept-container"]}>
  <div className={style["concept-wrapper"]}>
  <div className={style["title-section"]}>
    <div className={style["title-section-left"]}>
      <h1 className={`${style["title-section-heading"]} global-h1`}>Questions</h1>
    </div>
    <div className={style["title-section-right"]}>
      {SearchBoxComponent()}
      {/* {ChapterTopicToggleComponent()} */}
      {ExamspaceSpaceFolderComponent()}
    </div>
  </div>
  
  <section style={{display: resource_available === true && topic_available === true && displayLoadingUI === true ? "flex" : "none"}} className={style["loading-icon"]}>
    <LoadingIcon/>
  </section>
  
  <section className={style["concept-empty-state-container"]} style={resource_available === false && topic_available === false && displayLoadingUI === false? { display: "flex" } : { display: "none" }}>
  {/* <section className={style["concept-empty-state-container"]} style={false? { display: "flex" } : { display: "none" }}> */}
    <div className={style["empty-state-content"]}>
      {/* upload file UI */}
      <div className={style["empty-state-upload-file"]}>
        <p>Upload at least 1 document to see the main {chapters_topics_btn === "chapters" ? "Chapters" : "Topics"} to learn !!</p>
        <FileUploadComponent
          ref={fileUploadRef}
          onUploadSuccess={handleUploadSuccess}
          examspaceId={global_default_examspace_id.current}
        />
        <button onClick={triggerFileUpload}>
          <img src={uploadIcon} alt="upload icon" />
          Upload a file
        </button>
      </div>
    </div>
  </section>
  
  {/* Generate question UI */}
  <section className={style["concept-empty-state-container"]} style={resource_available === true && topic_available === false && displayLoadingUI === false ? { display: "flex" } : { display: "none" }}>
  {/* <section className={style["empty-concepts-loader"]} style={true ? { display: "flex" } : { display: "none" }}> */}
    <div className={style["empty-concepts-loader-content"]}>
      <img src={loadingsmall} alt="loading" />
        <p className={style["empty-concepts-loader-title"]}>
          We are extracting the main {chapters_topics_btn === "chapters" ? "Chapters" : "Topics"}
        </p>
        <div 
          className={style["empty-concepts-loader-description"]}
          style={chapters_topics_btn === "topics" ? {display: "block"} : {display: "none"}}>
          <p>It can take a few minutes. You can start <br/> practicing from the Chapters view</p>
          <button onClick={()=>{switchTopicsType({topics_type: "chapters"})}}>
            Switch to Chapters
          </button>
        </div>
    </div>
  </section> 
  
  
  {
  resource_available === true &&
  topic_available === true &&
  displayLoadingUI === false &&
  chapters_topics_btn === "chapters" &&
  conceptdata.length > 0 ? (
    // false > 0
    <>
      {
        // push mode top
        sharedUserInfo.plan_type === "free" &&
        sharedUserInfo.payment_history_count > 0
        ?
          <>
            <div className={style["topics-push-mode-top"]}>
              <div className={style["topics-push-mode-top-left"]}>
                <h2>Your Push Mode has expired</h2>
                <p>You are now back in free mode with 1 document and limited questions.</p>
              </div>
              <div className={style["topics-push-mode-top-right"]}>
                {showGoUnlimitedBtn("Reactivate subscribtion")}
              </div>
            </div>          
          </>
        :
          <>
          </>
      }     
      <div className={style["resource-list"]}>
        {conceptdata.map((resourceItem, resourceIndex) => (
          <article className={style["resource-card"]} key={resourceIndex}>
            <section className={style["resource-card-header"]}>
              <div className={style["resource-card-left"]}>
                <h2
                  className={style["resource-card-title"]}
                  onClick={(e) => {
                    handleResourceTitleEdit(e, resourceItem);
                    localStorage.setItem("lastSelectedResourceId", resourceItem.resource_id);
                  }}
                  onInput={(e) => {
                    modifiedResourceTitle.current = e.target.innerText;
                  }}
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                >
                  {resourceItem.resource_title}
                </h2>
              </div>
              {resourceItem.topic_list.length > 0 ? (
                // false
                <>
                  <div className={style["resource-card-right"]}>
                    <div className={style["resource-card-right-content"]}>
                      <div className={style["resource-card-score-box"]}>
                        {showProgessBar({
                          answered: resourceItem.num_correct_questions,
                          unanswered: resourceItem.num_unanswered_questions,
                          wrong: resourceItem.num_wrong_questions,
                          reread: resourceItem.num_reread_questions,
                        })}
                      </div>
                      <div className={style["resource-card-resource-info"]}>
                        <p>{resourceItem.topics_count} chapters</p>
                        <p>{resourceItem.total_question_count} questions</p>
                      </div>
                    </div>
                    <div
                      className={style["resource-card-open-handle"]}
                      id={`resource-card-open-handle-${resourceItem.resource_id}`}
                      onClick={(e) => {
                        openTopicList(resourceItem.resource_id);
                      }}
                    >
                      <DownArrowIcon />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={style["resource-card-right"]}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div className={style["resource-topic-generating"]}>
                      {showLoadingGif("Loading your questions..")}
                    </div>
                  </div>
                </>
              )}
            </section>
            <div
              className={style["resource-card-topic-list"]}
              id={`resource-card-topic-list-${resourceItem.resource_id}`}
            >
              {resourceItem.topic_list.length > 0 ? (
                // false
                <>
                  {resourceItem.topic_list.map((topicItem, topicIndex) => (
                    <div className={style["resource-topic-card"]} key={topicIndex}>
                      <div className={style["resource-topic-card-left"]}>
                        <div
                          className={style["resource-topic-card-topic-title"]}
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                          onClick={(e) => {
                            handleConceptTitleEdit(e, topicItem);
                            localStorage.setItem(
                              "lastSelectedResourceId",
                              resourceItem.resource_id,
                            );
                          }}
                          onInput={(e) => {
                            modifiedConceptTitle.current = e.target.innerText;
                          }}
                        >
                          {topicItem.name}
                        </div>
                        <div className={style["resource-topic-card-topic-pages"]}>
                          pages {`${topicItem.start_page} - ${topicItem.end_page}`}
                        </div>
                      </div>
                      <div className={style["resource-topic-card-right"]}>
                        {
                          Object.keys(sharedUserInfo).length > 0 &&
                          sharedUserInfo.plan_type === "free" &&
                          sharedUserInfo.payment_history_count <= 0 &&
                          topicItem.is_locked === true
                          ?
                            (
                              <>
                                <div className={style["chapters-go-unlimited-btn"]}>
                                  {
                                    sharedUserInfo.plan_type === "free" &&
                                    sharedUserInfo.payment_history_count <= 0
                                    ? 
                                      <>
                                        {showGoUnlimitedBtn()}
                                      </>
                                    :
                                      <>
                                        {showGoUnlimitedBtn("Reactivate subscribtion")}
                                      </>
                                  }
                                </div>                                
                              </>
                            )
                            :
                            (
                              topicItem.total_question_count > 0 ? (
                                // false
                                <>
                                  <div className={style["resource-topic-card-right-content"]}>
                                    <div className={style["resource-topic-card-topic-scores"]}
                                      style={
                                        sharedUserInfo.plan_type === "free" &&
                                        sharedUserInfo.payment_history_count > 0 &&
                                        topicItem.is_locked === true
                                        ?
                                          {
                                            filter: "blur(4px)",
                                          }
                                        :
                                        {}
                                      }                                  
                                    >
                                      {showProgessBar({
                                        answered: topicItem.num_correct_questions,
                                        unanswered: topicItem.num_unanswered_questions,
                                        wrong: topicItem.num_wrong_questions,
                                        reread: topicItem.num_reread_questions,
                                      })}
                                    </div>
                                    <div className={style["resource-topic-card-btns"]}
                                      style={
                                        sharedUserInfo.plan_type === "free" &&
                                        sharedUserInfo.payment_history_count > 0 &&
                                        topicItem.is_locked === true
                                        ?
                                          {
                                            pointerEvents: "none",
                                            opacity: "0.3",
                                          }
                                        :
                                        {}
                                      }
                                    >
                                      <div
                                        onClick={() => {
                                          navigate(
                                            `/conceptquestion?topic_type=chapters&topic_id=${topicItem.topic_id}&resource_id=${resourceItem.resource_id}`,
                                          );
                                        }}
                                        className={style["see-all-questions-btn"]}
                                      >
                                        <EyeIcon />
                                        {window_width >= 1000 ? (
                                          <p>See {topicItem.total_question_count} questions</p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div
                                        onClick={(e) => {
                                          navigate(`/quiz?topicId=${topicItem.topic_id}`);
                                        }}
                                        className={style["practise-btn"]}
                                      >
                                        {window_width >= 1000 ? (
                                          <>
                                            <PlayIcon />
                                            <p>Practice</p>
                                          </>
                                        ) : (
                                          <>
                                            <PlayIconWithCircle />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className={style["resource-topic-generating"]}>
                                    {showLoadingGif("Loading your questions..")}
                                  </div>
                                </>
                              )
                            )
                        }                        
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </article>
        ))}
      </div>
    </>
  ) : (
    <></>
  )
}


{
  resource_available === true &&
  topic_available === true &&
  displayLoadingUI === false &&
  chapters_topics_btn === "topics" &&
  Object.keys(sharedUserInfo).length > 0 && newTopicsData.length > 0 ? (
    // false > 0
    <>
      {
        // push mode top
        sharedUserInfo.plan_type === "free" &&
        sharedUserInfo.payment_history_count > 0
        ?
          <>
            <div className={style["topics-push-mode-top"]}>
              <div className={style["topics-push-mode-top-left"]}>
                <h2>Your Push Mode has expired</h2>
                <p>Reactivate your subscribtion to unlock all Topics</p>
              </div>
              <div className={style["topics-push-mode-top-right"]}>
                {showGoUnlimitedBtn("Reactivate subscribtion")}
              </div>
            </div>          
          </>
        :
          <>
          </>
      }    
      <div className={`${style["topics-ui-card-list"]}`}>
          {newTopicsData.map((topicItem, topicIndex) => (
            topicItem.is_locked === true &&
            sharedUserInfo.plan_type === "free" &&
            sharedUserInfo.payment_history_count <= 0 
            ? 
              <>
              </> 
            :
              <>
                <div className={`${style["topics-ui-card"]}`} key={topicIndex}>
                  <div className={`${style["topics-ui-card-left"]}`}>
                    <div
                      className={`${style["topics-ui-card-title"]}`}
                      // contentEditable={true}
                      // suppressContentEditableWarning={true}
                      // onClick={(e)=>{handleConceptTitleEdit(e, topicItem); localStorage.setItem("lastSelectedResourceId", resourceItem.resource_id)}}
                      // onInput={(e)=>{
                      //     modifiedConceptTitle.current = e.target.innerText
                      // }}
                    >
                      {topicItem.name}
                    </div>
                  </div>
                  <div className={`${style["topics-ui-card-right"]}`}>
                    {
                      topicItem.total_question_count > 0 ? (
                        <>
                          <div className={`${style["topics-ui-card-right-content"]} `}>
                            <div className={`${style["topics-ui-card-right-scores"]}`}
                              style={
                                sharedUserInfo.plan_type === "free" &&
                                sharedUserInfo.payment_history_count > 0 &&
                                topicItem.is_locked === true
                                ?
                                  {
                                    filter: "blur(4px)",
                                  }
                                :
                                {}
                              }
                            >
                              {
                              showProgessBar({
                                answered: topicItem.num_correct_questions,
                                unanswered: topicItem.num_unanswered_questions,
                                wrong: topicItem.num_wrong_questions,
                                reread: topicItem.num_reread_questions,
                              })
                              }
                            </div>
                            {
                              topicItem.is_update_completed === false
                              ?
                                <>
                                  <div className={`${style["topics-ui-card-right-polling-gif"]}`}>
                                    <img src={smallSVG} alt="loading gif" />
                                  </div>
                                </>
                              :
                                <></>
                            }
                            <div className={`${style["topics-ui-card-btns"]}`}
                              style={
                                sharedUserInfo.plan_type === "free" &&
                                sharedUserInfo.payment_history_count > 0 &&
                                topicItem.is_locked === true
                                ?
                                  {
                                    pointerEvents: "none",
                                    opacity: "0.3",
                                  }
                                :
                                  {}
                              }
                            >
                              <div
                                onClick={() => {
                                  navigate(
                                    `/conceptquestion?topic_type=topics&topic_id=${topicItem.topic_id}&examspace_id=${global_default_examspace_id.current}`,
                                  );
                                }}
                                className={style["see-all-questions-btn"]}
                              >
                                <EyeIcon />
                                {window_width >= 1000 ? <p>See {topicItem.total_question_count} questions</p> : <></>}
                              </div>
                              <div
                                onClick={(e) => {
                                  navigate(`/quiz?topicId=${topicItem.topic_id}`);
                                }}
                                className={style["practise-btn"]}
                              >
                                {window_width >= 1000 ? (
                                  <>
                                    <PlayIcon />
                                    <p>Practice</p>
                                  </>
                                ) : (
                                  <>
                                    <PlayIconWithCircle />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={style["resource-topic-generating"]}>{showLoadingGif("Loading your questions..")}</div>
                        </>
                      )
                    }
                  </div>
                </div>
              </>
          ))}
          {/* push mode bottom */}
          {
            sharedUserInfo.plan_type === "free" &&
            sharedUserInfo.payment_history_count <= 0
            ?
              <>
                <div className={style["topics-push-mode-bottom"]}>
                  <div className={style["topics-push-mode-bottom-left"]}>
                    <h2>See all Topics from your files</h2>
                    <p>Unlock all Topics and monitor your progress</p>
                  </div>
                  <div className={style["topics-push-mode-bottom-right"]}>
                    {showGoUnlimitedBtn()}
                  </div>
                </div>          
              </>
            :
              <>
              </>
          }
      </div>
    </>
  ) : (
    <></>
  )
}

    </div>
    </main>
    
    {/* <ExamSpacePopup data={ExamSpacesList} popup={showExamspace} close={handleClosePopUp} reCallGet={getAllExamSpacesList} /> */}
    <ToastContainer />
    </>
  );
  
}
