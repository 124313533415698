import React, { useState, useRef, useEffect } from 'react'
import style from "./Manage.module.scss";
import {
    GetUserInfoAPI,
    updateUserProfile,
    getPaymentHistory,
    cancelSubscription,
    resumeSubscription,
    scheduleSubscriptionAPI
} from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from '../../Components/Sidebar/Sidebar';
import { Link, useLocation, useNavigate } from "react-router-dom";
import rightArrow from "../../assets/right_arrow.png"
import loadingSvg from "../../assets/loading-svg.svg"
import tickIcon from "../../assets/tick_with_round.png"
import clapIcon from "../../assets/new/clap-icon.svg"




export default function Manage() {
    // const [show_password,set_show_password]=useState(false)
    // const [username_editable,set_username_editable]=useState(true)
    // const [password_editable,set_password_editable]=useState(true)
    // const [username_data,set_username_data]=useState(true)
    // const [auth_provider,set_auth_provider]=useState("email")

    const navigate = useNavigate();

    const [payment_history_array, set_payment_history_array] = useState([])
    const [user_info_array, set_user_info_array] = useState([])

    const [show_bill_table, set_show_bill_table] = useState(false)
    // const [show_cancel_popup, set_show_cancel_popup]=useState({
    //     "show_popup": true,
    //     "content": "downgrade-done"
    // })
    const [show_cancel_popup, set_show_cancel_popup] = useState({
        "show_popup": false,
        "content": "warning"
    })

    const [username, set_username] = useState("")
    const [username_backup, set_username_backup] = useState("")
    const [password, set_password] = useState("")
    const [plan_type, set_plan_type] = useState("free")
    const [plan_end_date, set_plan_end_date] = useState("")
    const [current_plan_subscription_end_date, set_current_plan_subscription_end_date] = useState("")
    const [subscription_status, set_subscription_status] = useState(null)
    const [show_plan_end_date, set_show_plan_end_date] = useState(null)
    const [sub_plan_type, set_sub_plan_type] = useState("1_month")
    const [subscriptionScheduleStatus, setSubscriptionScheduleStatus] = useState("")
    const [original_plan_end_date, set_original_plan_end_date] = useState("")


    const username_input_element = useRef(null);
    const password_input_element = useRef(null);
    const email_input_element = useRef(null);
    const plan_end_date_unmodified = useRef(null);



    useEffect(() => {
        // payment failed error message
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        if (status === "upgrade_success") {
            set_show_cancel_popup({ "show_popup": true, "content": "upgrade-done" })
        } else if (status === "downgrade_success") {
            set_show_cancel_popup({ "show_popup": true, "content": "downgrade-done" });
        }
        initializeComponent();
    }, []);

    async function initializeComponent() {
        let api_response = await GetUserInfoAPI();
        if (api_response.data.length > 0) {
            planEndDateCalculate(api_response.data[0]["plan_end_date"]);
            set_subscription_status(api_response.data[0]["subscription_status"])
            set_username(api_response.data[0]["name"]);
            set_username_backup(api_response.data[0]["name"]);
            setSubscriptionScheduleStatus(api_response.data[0]["subscription_schedule_status"])
            set_plan_type(api_response.data[0]["plan_type"]);
            set_original_plan_end_date(api_response.data[0]["plan_end_date"])
            if (api_response.data[0]["plan_type"] === "30_days") {
                if (api_response.data[0]["subscription_schedule_status"] === "upgrade") {
                    set_sub_plan_type("3_month");
                } else {
                    set_sub_plan_type("1_month");
                }
            } else if (api_response.data[0]["plan_type"] === "3_months") {
                if (api_response.data[0]["subscription_schedule_status"] === "downgrade") {
                    set_sub_plan_type("1_month");
                } else {
                    set_sub_plan_type("3_month");
                }
            }
            set_user_info_array(api_response.data);

            plan_end_date_unmodified.current = api_response.data[0]["plan_end_date"];
            const date = new Date(api_response.data[0]["plan_end_date"]);
            const formattedDate = date.toLocaleDateString('en-GB');
            set_plan_end_date(formattedDate);

            const current_plan_subscription_end_date_1 = new Date(api_response.data[0]["current_plan_subscription_end_date"]);
            set_current_plan_subscription_end_date(current_plan_subscription_end_date_1.toLocaleDateString('en-GB'));

            if (api_response.data[0]["auth_provider"] === "email") {
                password_input_element.current.style.pointerEvents = "auto"
            } else {
                password_input_element.current.style.pointerEvents = "none"
            }
        } else {
            toast.error(api_response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        }

        let payment_response = await getPaymentHistory();
        if (payment_response.data.length > 0) {
            set_payment_history_array(payment_response.data)
        }
    }

    function planEndDateCalculate(plan_end_date) {
        let plan_end_date_with_time = new Date(plan_end_date)
        let plan_end_date_without_time = plan_end_date_with_time.setHours(0,0,0,0);
        let today_date = new Date().setHours(0,0,0,0);
        if(today_date > plan_end_date_without_time){
            set_show_plan_end_date(false);
        } else {
            set_show_plan_end_date(true);
        }
    }

    async function handleCancelSubscription() {
        set_show_cancel_popup({ "show_popup": true, "content": "loading" });
        let api_response = await cancelSubscription();
        if(api_response){
            if (api_response.status === true){
                if (api_response.data[0]["subscription_status"] === "cancelled") {
                    set_show_cancel_popup({ "show_popup": true, "content": "done" });
                    GetUserInfoAPI();
                    initializeComponent();
                } else {
                    set_show_cancel_popup({ "show_popup": false, "content": "loading" });
                }
            } else {
                set_show_cancel_popup({ "show_popup": false, "content": "loading" });
                toast.error(api_response.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                });
            }
        }
    }

    function profileEnableUpdateBtn() {
        if (username_backup === username || password === "") {
            return false;
        } else {
            return true;
        }
    }


    async function handleUpdateProfile() {
        if (username_backup !== username || password !== "") {
            let body_json = {};
            if (username_backup !== username) {
                body_json["username"] = username;
            }
            if (password !== "") {
                body_json["password"] = password;
            }
            let update_profile_res = await updateUserProfile(body_json);

            if (update_profile_res.message.toLowerCase() === "user profile updated successfully") {
                toast.success("user profile updated successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                });
                set_username(update_profile_res.data[0]["username"]);
                set_password("");
            } else {
                toast.error(update_profile_res.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                });
            }
        } else {
            toast.error("Make some changes to update!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
            });
        }
    }

    async function resumeSubscriptionFun() {
        let api_response = await GetUserInfoAPI();

        if (api_response.data.length > 0) {
            if (api_response.data[0]["plan_type"] !== "free") {
                let api_res = await resumeSubscription();
                if (api_res.data[0]["subscription_status"] === "active") {
                    navigate("/home?paymentstatus=true&plan_name=30_days&plan_value=6.90");
                } else {
                    toast.error(api_res.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: true,
                    });
                }
            } else {
                navigate("/pushmode");
            }
        }
    }

    function format3MonthPlanDate(plan_end_date) {
        console.log('111111111111111', plan_end_date)
        // Parse the input date string
        const originalDate = new Date(plan_end_date);
        
        // Check if date is valid
        if (isNaN(originalDate.getTime())) {
            console.error("Invalid date format:", plan_end_date);
            return "Invalid date";
        }
        
        // Add 90 days (3 months)
        const newDate = new Date(originalDate);
        newDate.setDate(originalDate.getDate() + 90);
        
        // Format as DD/MM/YYYY to match the app's date format
        const day = newDate.getDate().toString().padStart(2, '0');
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const year = newDate.getFullYear();
        
        const formattedDate = `${day}/${month}/${year}`;
        console.log('formattedDate',formattedDate)
        
        return formattedDate;
    }



    async function handleScheduleSubscription(request_type) {
        let new_plan = "";
        if (request_type === "upgrade") {
            new_plan = "3_months"
        } else if (request_type === "downgrade") {
            new_plan = "30_days"
        }
        let json_data = {
            "request_type": request_type,
            "new_plan": new_plan,
        }
        set_show_cancel_popup({ "show_popup": true, "content": "loading" })
        let api_response = await scheduleSubscriptionAPI(json_data);
        if (api_response.status === true) {
            if (request_type === "downgrade") {
                if (api_response.data[0]["paypal_link"] == "") {
                    set_show_cancel_popup({ "show_popup": true, "content": "downgrade-done" });
                    initializeComponent();
                } else {
                    window.location.replace(api_response.data[0]["paypal_link"]);
                }
            } else if (request_type === "upgrade") {
                if (api_response.data[0]["paypal_link"] == "") {
                    set_show_cancel_popup({ "show_popup": true, "content": "upgrade-done" });
                    initializeComponent();
                } else {
                    window.location.replace(api_response.data[0]["paypal_link"]);
                }
            }
        } else {
            alert("error")
        }
    }

    function handleUpgradeSubscription() {
        handleScheduleSubscription("upgrade")
        // if(plan_type === "free"){
        //     navigate("/pushmode?sub_plan_type=3_month")
        // } else if(plan_type === "30_days"){
        //     set_show_cancel_popup({"show_popup": true, "content": "upgrade-confirm"})
        // } else if(plan_type === "3_months"){
        //     set_show_cancel_popup({"show_popup": true, "content": "upgrade-confirm"})
        // }
    }

    // function getFutureSubDate(){
    //     const today = new Date(plan_end_date_unmodified.current);
    //     const result = new Date(today);
    //     result.setMonth(result.getMonth() + 3);
    //     // Handle cases where adding months changes the day (e.g., adding 1 month to Jan 31)
    //     if (result.getDate() !== today.getDate()) {
    //         result.setDate(0); // Set to the last day of the previous month
    //     }
    //     const year = result.getFullYear();
    //     const month = String(result.getMonth() + 1).padStart(2, '0'); // Months are 0-based, pad with leading 0
    //     const day = String(result.getDate()).padStart(2, '0');
    //     return `${day}/${month}/${year}`;
    // }


    return (
        <>
            {/* <Sidebar/> */}
            <ToastContainer />
            <main className={style["container"]}>
                <div className={style["content"]}>
                    <section className={style["subscription"]}>
                        <h2>Subscription</h2>
                        <div className={style["sub-cards-container"]}>
                            <div className={style["sub-card-1"]}>
                                <div style={sub_plan_type === "3_month" && subscription_status === "active" ? { display: "block" } : { display: "none" }} className={style["offer-green-bar"]}>
                                    Best deal. You save 15% every month.
                                </div>
                                <div style={sub_plan_type === "3_month" && subscription_status === "active" ? { display: "block" } : { display: "none" }} className={style["offer-white-bar"]}></div>
                                <div className={style["subscription-sec-1"]}>
                                    <h3>Your plan:
                                        {
                                            subscription_status === "cancelled" || subscription_status === null
                                                ?
                                                <>
                                                    <span style={{ color: "#27345E" }}> Free mode</span>
                                                </>
                                                :
                                                <>
                                                    <span style={{ color: "#F18F01" }}> Push mode{" "}</span>
                                                    <span
                                                        style={{
                                                            fontFamily: "var(--light-font)",
                                                            fontWeight: "400",
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        {
                                                            sub_plan_type === "1_month"
                                                                ?
                                                                "(monthly)"
                                                                :
                                                                sub_plan_type === "3_month"
                                                                    ?
                                                                    "(3 months)"
                                                                    :
                                                                    ""
                                                        }
                                                    </span>
                                                </>
                                        }
                                    </h3>
                                    {
                                        subscription_status === "cancelled" || subscription_status === null
                                            ?
                                            <>
                                                <p>You have limited questions and</p>
                                                <p>maximum 2 documents upload</p>
                                            </>
                                            :
                                            <>
                                                <p>Unilimited documents</p>
                                                <p>All questions available</p>
                                            </>
                                    }
                                </div>
                                {
                                    subscription_status === null
                                        ?
                                        <>
                                            <div className={style["subscription-sec-4"]}>
                                                <p onClick={() => { navigate("/pushmode") }}>Go unlimited {">"}</p>
                                            </div>
                                        </>
                                        :
                                        subscription_status === "cancelled"
                                            ?
                                            <>
                                                {
                                                    show_plan_end_date
                                                        ?
                                                        <>
                                                            <div className={style["subscription-sec-2"]}>
                                                                <h3>
                                                                    Push mode will finish:
                                                                    <span> {plan_end_date}</span>
                                                                </h3>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                                <div className={style["subscription-sec-4"]}>
                                                    <p onClick={() => { resumeSubscriptionFun() }}>Activate the push mode {">"}</p>
                                                </div>
                                            </>
                                            :
                                            subscription_status === "active"
                                                ?
                                                <>
                                                    <div className={style["subscription-sec-2"]}>
                                                        <h3>
                                                            Next renewal:
                                                            <span> {plan_end_date}</span>
                                                        </h3>
                                                    </div>
                                                    <div className={style["subscription-sec-3"]}>
                                                        <button onClick={() => { set_show_cancel_popup({ "show_popup": true, "content": "warning" }) }}>
                                                            Cancel subscription
                                                        </button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                }
                            </div>
                            <div className={style["sub-card-2"]}
                                style={plan_type === "free" ? { display: "none" } : { display: "block" }}
                            >
                                <div style={sub_plan_type === "1_month" ? { display: "block" } : { display: "none" }}>
                                    <div className={style["offer-green-bar"]}>
                                        Save money. Transform your study routine.
                                    </div>
                                    <div className={style["offer-white-bar"]}></div>
                                    <div className={style["three-month-sub"]}>
                                        <p className={style["three-month-sub-txt1"]}>
                                            Switch to <span>{" "}3 months{" "}</span>plan
                                        </p>
                                        <p className={style["three-month-sub-txt2"]}>
                                            €5,80<p>/month</p><span>-15%</span>
                                        </p>
                                        <p className={style["three-month-sub-txt3"]}>
                                            Instead of €6,90/month
                                        </p>
                                        <button
                                            onClick={() => { set_show_cancel_popup({ "show_popup": true, "content": "upgrade-confirm" }); }}
                                        >
                                            Save money
                                        </button>
                                    </div>
                                </div>
                                <div style={sub_plan_type === "3_month" ? { display: "block" } : { display: "none" }}>
                                    <div className={style["one-month-sub"]}>
                                        <p className={style["one-month-sub-txt1"]}>
                                            Downgrade to 1 month plan
                                        </p>
                                        <p className={style["one-month-sub-txt2"]}>
                                            €6,90
                                            <p>/month</p>
                                            <span>+15%</span>
                                        </p>
                                        <p className={style["one-month-sub-txt3"]}>
                                            You are now paying €5,80/month
                                        </p>
                                        <button
                                            style={subscriptionScheduleStatus === "downgrade"
                                                ? { pointerEvents: "none", opacity: "0.5" } : { pointerEvents: "auto", opacity: "1" }}
                                            onClick={() => { set_show_cancel_popup({ "show_popup": true, "content": "downgrade-confirm" }) }}
                                        >
                                            Downgrade to monthly
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {
                        subscription_status !== null
                            ?
                            <>
                                <section className={style["bill-history"]}>
                                    <div className={style["bill-history-header"]} onClick={() => { set_show_bill_table(!show_bill_table) }}>
                                        <img className={style["bill-history-arrow"]}
                                            src={rightArrow} alt="icon"
                                            style={show_bill_table === true ? { transform: "rotate(90deg)" } : { transform: "rotate(0deg)" }} />
                                        <h2>Your bills</h2>
                                    </div>
                                    <div className={style["bill-history-body"]}
                                        style={show_bill_table === true ? { display: "inline-block" } : { display: "none" }}>
                                        <div className={style["bill-history-table"]}>
                                            <div className={style["table-header"]}>
                                                <h3 className={style["table-header-col1"]}>Date</h3>
                                                <h3 className={style["table-header-col2"]}>Import</h3>
                                                <h3 className={style["table-header-col3"]}>Receipt</h3>
                                            </div>
                                            <div className={style["table-content"]}>
                                                {
                                                    payment_history_array.length
                                                        ?
                                                        payment_history_array.map((item) => {
                                                            const date = new Date(item["plan_end_date"]);
                                                            const formattedDate = date.toLocaleDateString('en-GB');
                                                            return (
                                                                <>
                                                                    <div className={style["table-row"]}>
                                                                        <p className={style["table-header-col1"]}>{formattedDate}</p>
                                                                        <p className={style["table-header-col2"]}>€
                                                                            {
                                                                                item["plan_price"] === 6.9
                                                                                    ?
                                                                                    "6,90"
                                                                                    :
                                                                                    item["plan_price"]
                                                                            }
                                                                        </p>
                                                                        <div className={style["table-header-col3"]}>
                                                                            <button
                                                                                style={item["invoice_url"] === "" ?
                                                                                    { pointerEvents: "none", backgroundColor: "#fff" } : { pointerEvents: "auto", backgroundColor: "#F0F5FF" }}
                                                                                onClick={() => { window.open(item["invoice_url"], '_blank'); }}>
                                                                                {
                                                                                    item["invoice_url"] === ""
                                                                                        ?
                                                                                        "-"
                                                                                        :
                                                                                        <>
                                                                                            Open
                                                                                        </>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div className={style["bill-history-footer"]}>
                                            <p>Any questions? Write us at help@dende.ai </p>
                                        </div>
                                    </div>
                                </section>
                            </>
                            :
                            <>
                            </>
                    }
                    <section className={style["profile"]}>
                        <h2>Profile</h2>
                        <div className={style["profile-inputs"]}>
                            <input type="text" value={username} onChange={(e) => { set_username(e.target.value); }} placeholder='Name' />
                            <input className={style["password-input"]} ref={password_input_element} type="text" value={password} onChange={(e) => { set_password(e.target.value); }} placeholder='Password' />
                        </div>
                        <div className={style["profile-btn"]}>
                            <button
                                onClick={() => { handleUpdateProfile() }}>
                                Update
                            </button>
                        </div>
                    </section>
                </div>
            </main>

            <div className={style["manage-popup-backdrop"]}
                style={show_cancel_popup.show_popup ? { display: "block" } : { display: "none" }}
            >
                <div className={style["manage-popup-content"]}>
                    <section className={style["manage-popup-cancel"]}
                        style={show_cancel_popup.content === "warning" ? { display: "block" } : { display: "none" }}>
                        <div className={style["manage-popup-cancel-sec1"]}>
                            Time to start a new chapter? <br /> Be great as always!
                        </div>
                        <div className={style["manage-popup-cancel-sec2"]}>
                            If you cancel now, you will still have access to all the
                            features untill the end of your plan period <br />
                            ({plan_end_date})
                        </div>
                        <div className={style["manage-popup-cancel-sec3"]}>
                            After that day, you will return to the free mode with
                            limitations. But your content will stay there and you can
                            reactivate the push mode anytime you need it.
                        </div>
                        <div className={style["manage-popup-cancel-sec4"]}>
                            <button onClick={() => { handleCancelSubscription() }}>
                                Confirm to cancel
                            </button>
                            <p onClick={() => { set_show_cancel_popup({ "show_popup": false, "content": "warning" }) }}>
                                Not now, keep your subscibtion {">"}
                            </p>
                        </div>
                    </section>
                    <section className={style["manage-popup-loading"]}
                        style={show_cancel_popup.content === "loading" ? { display: "flex" } : { display: "none" }}>
                        <img src={loadingSvg} alt="loading gif" />
                        {/* <div className={style["manage-popup-loading-sec1"]}>
                        </div> */}
                    </section>
                    <section className={style["manage-popup-success"]}
                        style={show_cancel_popup.content === "done" ? { display: "block" } : { display: "none" }}>
                        <div className={style["manage-popup-success-sec1"]}>
                            <img src={tickIcon} alt="icon" />
                        </div>
                        <div className={style["manage-popup-success-sec2"]}>
                            <h2>Your subscription is cancelled </h2>
                        </div>
                        <div className={style["manage-popup-success-sec3"]}>
                            <p>You will not be automatically charged at
                                the end of your plan period. We also
                                sent you an email with this confirmation.</p>
                        </div>
                        <div className={style["manage-popup-success-sec4"]}>
                            <button onClick={() => { set_show_cancel_popup({ "show_popup": false, "content": "warning" }); window.location.reload() }}>
                                Ok
                            </button>
                        </div>
                    </section>
                    <section className={style["downgrade-plan"]}
                        style={show_cancel_popup.content === "downgrade-confirm" ? { display: "block" } : { display: "none" }}>
                        <div className={style["downgrade-plan-sec2"]}>
                            <h2>Are you sure do you want to downgrade to monthly subscription?</h2>
                        </div>
                        <div className={style["downgrade-plan-sec3"]}>
                            <p>you will be automatically downgrade to monthly subscription after current plan end date.</p>
                        </div>

                        <button
                            onClick={() => { handleScheduleSubscription("downgrade") }}
                            style={{
                                backgroundColor: "#F18F01",
                                padding: "5px 0px",
                                width: "100%",
                                textAlign: "center",
                                color: "#fff",
                                fontFamily: "var(--sub-title-font)",
                                fontSize: "20px",
                                fontWeight: "500",
                                marginTop: "10px",
                                borderRadius: "5px",
                            }}
                        >
                            Proceed to downgrade
                        </button>
                        <div
                            style={{
                                textAlign: "center",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginTop: "15px"
                            }}
                            onClick={() => { set_show_cancel_popup({ "show_popup": false, "content": "warning" }) }}
                        >
                            Cancel downgrade
                        </div>
                    </section>
                    <section className={style["downgrade-plan"]}
                        style={show_cancel_popup.content === "downgrade-done" ? { display: "block" } : { display: "none" }}>
                        <div className={style["downgrade-plan-sec1"]}>
                            <img src={tickIcon} alt="icon" />
                        </div>
                        <div className={style["downgrade-plan-sec2"]}>
                            <h2>Downgrade to monthly plan is scheduled</h2>
                        </div>
                        <div className={style["downgrade-plan-sec3"]}>
                            <p>Your monthly plan will start at the end of the actual push mode period {plan_end_date}</p>
                        </div>
                        <div className={style["downgrade-plan-sec4"]}>
                            <button onClick={() => {
                                set_show_cancel_popup({ "show_popup": false, "content": "warning" });
                                // remove params in url
                                const url = window.location.origin + window.location.pathname;
                                window.history.pushState({}, document.title, url);
                                window.location.reload()
                            }}>
                                Ok
                            </button>
                        </div>
                    </section>
                    <section className={style["upgrade-plan"]}
                        style={show_cancel_popup.content === "upgrade-confirm" ? { display: "block" } : { display: "none" }}>
                        <div
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <img src={clapIcon} alt="icon"
                                style={{
                                    height: "55px",
                                    width: "auto",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--sub-title-font)",
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "10px",
                            }}
                        >
                            Good idea! You are saving money
                            and transforming your study routine.
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--sub-title-font)",
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "15px",
                            }}
                        >
                            You pay today:
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--light-font)",
                                fontSize: "18px",
                                fontWeight: "400",
                                marginTop: "5px",
                            }}
                        >
                            {`17,40€ ( 5,80€ x 3 months`}
                            <span
                                style={{
                                    fontFamily: "var(--sub-title-font)",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    padding: "2px 6px",
                                    backgroundColor: "#34C759",
                                    color: "#fff",
                                    borderRadius: "30px",
                                    margin: "0px 5px",
                                }}
                            >
                                -15%
                            </span>
                            {`)`}
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--sub-title-font)",
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "15px",
                            }}
                        >
                            Next renewal:
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--light-font)",
                                fontSize: "18px",
                                fontWeight: "400",
                                marginTop: "5px",
                            }}
                        >
                            {
                                original_plan_end_date !== ""
                                ?
                                    format3MonthPlanDate(original_plan_end_date)
                                :
                                    <></>

                            }
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--light-font)",
                                fontSize: "16px",
                                fontWeight: "400",
                                marginTop: "15px",
                            }}
                        >
                            The 3 months plan will start at the end of your actual monthly plan, so you don’t lose any days.You can still cancel your subscription anytime.
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <button
                                onClick={() => { handleScheduleSubscription("upgrade") }}
                                style={{
                                    backgroundColor: "#34C759",
                                    padding: "5px 50px",
                                    textAlign: "center",
                                    color: "#fff",
                                    fontFamily: "var(--sub-title-font)",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    marginTop: "30px",
                                    borderRadius: "5px",
                                }}
                            >
                                Continue and save money
                            </button>
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginTop: "15px"
                            }}
                            onClick={() => { set_show_cancel_popup({ "show_popup": false, "content": "warning" }) }}
                        >
                            Cancel upgrade
                        </div>
                    </section>
                    <section className={style["upgrade-plan"]}
                        style={show_cancel_popup.content === "upgrade-done" ? { display: "block" } : { display: "none" }}>
                        <div
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <img src={tickIcon} alt="icon"
                                style={{
                                    height: "40px",
                                    width: "auto"
                                }}
                            />
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--sub-title-font)",
                                fontSize: "26px",
                                fontWeight: "500",
                                marginTop: "15px",
                                padding: "0px 30px",
                            }}
                        >
                            Your subscription is updated to 3 months plan
                        </div>
                        <div
                            className={style["upgrade-plan-done-text1"]}
                        >
                            The 3 months recurrent plan will start after the end of your actual push mode period ({current_plan_subscription_end_date}).
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--content-font)",
                                fontSize: "16px",
                                fontWeight: "400",
                                marginTop: "20px"
                            }}
                        >
                            It will then renew on:
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "var(--content-font)",
                                fontSize: "16px",
                                fontWeight: "400",
                                marginTop: "20px"
                            }}
                        >
                            {plan_end_date}
                        </div>
                        <div
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <button
                                onClick={() => {
                                    set_show_cancel_popup({ "show_popup": false, "content": "warning" });
                                    // remove params in url
                                    const url = window.location.origin + window.location.pathname;
                                    window.history.pushState({}, document.title, url);
                                    window.location.reload()
                                }}
                                style={{
                                    backgroundColor: "#F18F01",
                                    padding: "5px 50px",
                                    textAlign: "center",
                                    color: "#fff",
                                    fontFamily: "var(--sub-title-font)",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    marginTop: "25px",
                                    borderRadius: "5px",
                                }}
                            >
                                Back to home
                            </button>
                        </div>
                    </section>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}