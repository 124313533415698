import './App.scss';
import Navbar from './Components/Navbar/Navbar';
import Home from './Pages/Home/Home';
import { Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import Feedback from './Pages/Feedback/Feedback';
import Material from './Pages/Material/Material';
import Upload from './Pages/Uploadfile/Uploadfile';
import Linkadd from './Pages/Linkadd/Linkadd';
import Noteadd from './Pages/Noteadd/Noteadd';
import MCQ from './Pages/MCQ/MCQ';
import Message from './Pages/Message/Message';
import 'react-toastify/dist/ReactToastify.css';
import Help from './Pages/Help/Help';
import Profile from './Pages/Profile/Profile';
import Manage from './Pages/Manage/Manage';
import Question from './Pages/Question/Question';
import Topbar from './Components/Topbar/Topbar';
import Reply from './Pages/Reply/Reply';
import Carousel from './Pages/Carousel/Carousel';
import Aloha from './Pages/Aloha/Aloha';
import Library from './Pages/Library/Library';
import Users from './Pages/Users/Users';
import UserActivity from './Pages/UserActivity/UserActivity';
import UserDetail from './Pages/UserDetail/UserDetail';
import Statistic from './Pages/Statistic/Statistic';
import Chapters from './Pages/Library/Chapter';
import { useLocation } from 'react-router-dom';
import ChapterQuiz from './Pages/ChapterQuiz/ChapterQuiz';
import Privacy from './Pages/Privacy/Privacy';
import Terms from './Pages/Privacy/Terms';
import ChapterQuestions from './Pages/ChapterQuiz/ChapterQuestions';
import Congratulation from './Pages/Congratulation/Congratulation';
import CarouselNew from './Pages/Carousel/CarouselNew';
import SendUs from './Pages/Sendus/Sendus'
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PushMode from './Pages/PushMode/PushMode';
import Concept from './Pages/Concept/Concept';
import ConceptQuestion from './Pages/ConceptQuestion/ConceptQuestion'
import HomeNew from './Pages/home-new/HomeNew';
import GeneratedTopics from './Pages/GeneratedTopics/GeneratedTopics';
import TopicCompletedAlert from './Components/TopicCompletedAlert/TopicCompletedAlert';
import LimitExceedPopup from './Components/LimitExceedPopup/LimitExceedPopup';
import FeedbackPopup from './Components/FeedbackPopup/FeedbackPopup';
import Quiz from './Pages/Quiz/Quiz';
import InitialFileUpload from './Pages/InitialFileUpload/InitialFileUpload';
import Loading from './Pages/Loading/Loading'
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import FlashcardNew from './Pages/FlashcardNew/FlashcardNew';
import { install } from 'ga-gtag';
import { getApiUrl } from './helpers/requests';
import { LoginTopBar } from './Components/LoginTopBar/LoginTopBar';
import TopNotificationBanner from './Components/TopNotificationBanner/topNotificationBanner';
import Sidebar from './Components/Sidebar/Sidebar';
import Reread from './Pages/Reread/Reread';
import ReactPixel from 'react-facebook-pixel';

const PrtectedRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to={"/login"} />
  } else {
    return children
  }
}



function App() {
  // facebook
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);

  var apiUrl = getApiUrl();
  const [show_sidebar, set_show_sidebar] = useState(false);

  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.innerHTML = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${process.env.REACT_APP_GTM_ID}');
  `;
    // Append script to document head
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    // Create iframe
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    // Append iframe to noscript
    noscript.appendChild(iframe);
    // Append noscript to body
    document.body.appendChild(noscript);



    // Google analytics
    if (apiUrl === "https://app.dende.ai/api" || apiUrl === "https://staging.dende.ai/api") {
      install('G-K9ES37HT6F', { debugger: true, 'send_page_view': false });
    } else {
      install('G-GMWDDVX7G6', { debugger: true, 'send_page_view': false });
    }

    // Hotjar script
    function loadHotjarScript() {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3703134, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
    loadHotjarScript();
  }, []);

  const location = useLocation()

  const changenav = location.pathname === '/reply'

  const localToken = localStorage.getItem('token')
  const token = localToken ? localToken : false

  const [pathname, setPathName] = useState(window.location.pathname)

  useEffect(() => {
    // Create a script element
    if (pathname !== '/material') {

      const script = document.createElement('script');
      script.src = '//code.tidio.co/h84y7nn1ix0oebapqhfudfddrg93hdew.js';
      script.async = true;

      // Append the script to the head tag
      document.head.appendChild(script);

      // Clean up the effect
      return () => {
        // Remove the script when the component unmounts
        document.head.removeChild(script);
      };
    }

  }, [pathname]);

  useEffect(() => {
    showSideNavbar();
    ReactPixel.pageView();
  }, [location.pathname]);


  function showSideNavbar() {
    let path_list = ["/home", "/quiz", "/concept", "/flashcard", "/library", "/uploadfile", "/reread",
      "/manage", "/profile", "/pushmode", "/conceptquestion", "/topic-completed", "/users", "/statistics"];

    if (path_list.includes(location.pathname)) {
      set_show_sidebar(true);
    } else {
      set_show_sidebar(false);
    }
  }

  return (
    <div className="App">
      <Helmet>
      </Helmet>
      {
        show_sidebar
          ?
          <>
            {/* <TopNotificationBanner /> */}
            <Sidebar />
          </>
          :
          <>
          </>
      }
      <Routes>
        <Route path='/login-topbar' element={LoginTopBar} />

        <Route path='/' element={<Home />} />

        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />

        {/* <Route path='/topic-completed' element={<TopicCompletedAlert />} /> */}
        <Route path='/sendus' element={<SendUs />} />
        <Route path='/message' element={<Message />} />
        <Route path='/help' element={<Help />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/progressbox' element={<LimitExceedPopup />} />
        <Route path='/feedback-popup' element={<FeedbackPopup />} />

        <Route path='/feedback' element={<PrtectedRoute token={token} > <FeedbackPopup /> </PrtectedRoute>} />
        <Route path='/material' element={<PrtectedRoute token={token} > <Material /> </PrtectedRoute>} />
        <Route path='/initial-upload' element={<PrtectedRoute token={token} > <InitialFileUpload /> </PrtectedRoute>} />
        <Route path='/uploadfile' element={<PrtectedRoute token={token} > <Upload /> </PrtectedRoute>} />
        <Route path='/linkadd' element={<PrtectedRoute token={token} > <Linkadd /> </PrtectedRoute>} />
        <Route path='/noteadd' element={<PrtectedRoute token={token} > <Noteadd /> </PrtectedRoute>} />
        <Route path='/mcq' element={<PrtectedRoute token={token} > <MCQ /> </PrtectedRoute>} />
        <Route path='/mcq/:id' element={<PrtectedRoute token={token} > <MCQ /> </PrtectedRoute>} />
        <Route path='/chapter/:cid' element={<PrtectedRoute token={token} > <MCQ /> </PrtectedRoute>} />
        <Route path='/chapter/:id/questions' element={<PrtectedRoute token={token} > <ChapterQuestions /> </PrtectedRoute>} />
        <Route path='/profile' element={<PrtectedRoute token={token} > <Profile /> </PrtectedRoute>} />
        <Route path='/manage' element={<PrtectedRoute token={token} > <Manage /> </PrtectedRoute>} />
        <Route path='/finish' element={<PrtectedRoute token={token} > <Congratulation /> </PrtectedRoute>} />
        <Route path='/question' element={<PrtectedRoute token={token} > <Question /> </PrtectedRoute>} />
        <Route path='/topbar' element={<PrtectedRoute token={token} > <Topbar /> </PrtectedRoute>} />
        <Route path='/reply' element={<PrtectedRoute token={token} > <Reply /> </PrtectedRoute>} />
        <Route path='/carousel' element={<PrtectedRoute token={token} > <CarouselNew /> </PrtectedRoute>} />
        <Route path='/aloha' element={<PrtectedRoute token={token} > <Aloha /> </PrtectedRoute>} />
        <Route path='/library' element={<PrtectedRoute token={token} > <Library /> </PrtectedRoute>} />
        <Route path='/chapters' element={<PrtectedRoute token={token} > <Chapters /> </PrtectedRoute>} />
        <Route path='/users' element={<PrtectedRoute token={token} > <Users /> </PrtectedRoute>} />
        <Route path='/statistics' element={<PrtectedRoute token={token} > <Statistic /> </PrtectedRoute>} />
        <Route path='/user/:id' element={<PrtectedRoute token={token} > <UserDetail /> </PrtectedRoute>} />
        <Route path="/user-activity/:id" element={<PrtectedRoute token={token} > <UserActivity /> </PrtectedRoute>} />
        <Route path='/generated-topics' element={<PrtectedRoute token={token} > <GeneratedTopics /> </PrtectedRoute>} />
        <Route path='/quiz' element={<PrtectedRoute token={token} > <Quiz /> </PrtectedRoute>} />
        <Route path='/topic-completed' element={<PrtectedRoute token={token} > <TopicCompletedAlert /> </PrtectedRoute>} />
        <Route path='/pushmode' element={<PrtectedRoute token={token} > <PushMode /> </PrtectedRoute>} />
        <Route path='/home' element={<PrtectedRoute token={token} > <HomeNew /> </PrtectedRoute>} />
        <Route path='/flashcard' element={<PrtectedRoute token={token} > <FlashcardNew /> </PrtectedRoute>} />
        <Route path='/loading' element={<PrtectedRoute token={token} > <Loading /> </PrtectedRoute>} />
        <Route path='/concept' element={<PrtectedRoute token={token} > <Concept /> </PrtectedRoute>} />
        <Route path='/reread' element={<PrtectedRoute token={token} > <Reread /> </PrtectedRoute>} />
        <Route path='/conceptquestion' element={<PrtectedRoute token={token} > <ConceptQuestion /> </PrtectedRoute>} />
      </Routes>
    </div>
  );
}

export default App;
