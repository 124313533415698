import "./Quiz.scss";
import { useEffect, useState, useRef, useContext } from "react";
import { CurrentUserInfo, Message_data } from "../../Components/ContextStore/ContextStore";
import { useNavigate, useSearchParams  } from "react-router-dom";
import { parseISO, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears, isValid } from 'date-fns';
import {
  getUserAuthToken,
  getApiUrl,
  listAllUnskippedResources,
  getAllConcepts,
  getNewTopicsAPI,
  getQuestionUtility,
  getConceptInfoAPI,
  getConceptQuestionAPI,
  ExamSpaceUploadResource,
  GetUserInfoAPI,
  getAllExamSpaces,
  SendDendeEvents,
  getQuestionListAPI
} from "../../helpers/requests";
import thumbsDownIcon from "../../assets/thumb-down.png";
import swipeImg from "../../assets/swipe.png";
import FeedbackPopup from "../../Components/FeedbackPopup/FeedbackPopup";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { ToastContainer, toast } from "react-toastify";
import clickHandIcon from "../../assets/click-hand-icon.png";
import { AiOutlineRight, AiOutlineUpCircle } from "react-icons/ai";
import uploadIcon from "../../assets/upload-white-icon.png";
import loadingsmall from "../../assets/loading-svg-orange.svg";
import downArrow from "../../assets/down-arrow-drak-blue.png";
import { ReactComponent as FilterIcon} from "../../assets/new/filter-black-icon.svg";
import { ReactComponent as CloseBlackIcon} from "../../assets/new/close-black-icon.svg";
import questionIcon from "../../assets/question.png";
import searchBlackIcon from "../../assets/new/search-black-icon.svg";
import correctAnsIcon from "../../assets/new/correct-ans-icon.svg";
import orangeLock from "../../assets/orange_lock.png";
import lockIcon from "../../assets/lock.png";
import style from "./Quiz.module.scss";
import downArrowBlack from "../../assets/down-arrow-black.png";
import ContinuousThreeAnswer from "../../Components/ContinuousThreeAnswer/ContinuousThreeAnswer";
import Sidebar from "../../Components/Sidebar/Sidebar";
import blueThumbsDown from "../../assets/blue-thumbs-down.png";
import downArrowIcon from "../../assets/new/down-arrow.svg"
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
//For Zoom
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { Viewer } from "@react-pdf-viewer/core";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';
import rocketIcon from "../../assets/new/rocket.svg"

// before quiz ui revamp
export default function Quiz() {
  const { setQuestionData } = useContext(Message_data);
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();

  const [current_question_content, set_current_question_content] = useState([]);
  const [feedback_popup_options, set_feedback_popup_options] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [option_card_clickable, set_option_card_clickable] = useState(false);
  const [quiz_option_clicked, set_quiz_option_clicked] = useState(false);
  const [show_answer_in_feedback, set_show_answer_in_feedback] = useState(false);
  const [allownext, setAllowNext] = useState(false);
  const [nextswipe, setNextSwipe] = useState(false);
  const [data_available, set_data_available] = useState(true);
  const [resource_available, set_resource_available] = useState(true);
  const [question_available, set_question_available] = useState(true);
  const [concepts_data, set_concepts_data] = useState([]);
  const [plan_type, set_plan_type] = useState(localStorage.getItem("plan_type"));
  const [question_filter_array, set_question_filter_array] = useState([]);
  const question_filter_array_ref = useRef([]);
  const [first_three_questions_display, set_first_three_questions_display] = useState({});
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [show_question_filter, set_show_question_filter] = useState(true);
  const [show_quiz_filter, set_show_quiz_filter] = useState(false);
  const [show_topic_card_questions, set_show_topic_card_questions] = useState(false);
  const [chapters_topics_btn, set_chapters_topics_btn] = useState("chapters");
  const chapters_topics_btn_value = useRef("chapters");
  const [enableSuffleMode, setEnableSuffleMode] = useState(false);
  const enableSuffleModeRef = useRef(false);
  const [currentTopicData, setCurrentTopicData] = useState({});
  const [displayFreeUserPushmodeBar, setDisplayFreeUserPushmodeBar] = useState(false);
  const [displayFreeUserQuestionCounterBar, setDisplayFreeUserQuestionCounterBar] = useState(false);
  const [topicQuestionArray, setTopicQuestionArray] = useState([]);
  const [quizFilterState, setQuizFilterState] = useState([]);
  const quizFilterRef = useRef([]);
  //for PDF
  const [getpdf, setpdf] = useState(null);
  const [pagenumber, setPageNumber] = useState(0);
  const [startPosX, setStartPosX] = useState(null);
  const [endPosX, setEndPosX] = useState(null);
  const [pdfopen, setPdfOpen] = useState(false);
  const [pdfloaded, setPdfLoaded] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const pollIntervalId = useRef(null);
  const containerRef = useRef(null);
  const questions_metadata_list = useRef([]);
  const question_count = useRef(0);
  const quizOptionsContainer = useRef();
  const progressBarContainer = useRef();
  const progressBar = useRef();
  const quiz_hints_title = useRef(null);
  const quiz_hints_content = useRef(null);
  const quiz_guide_text = useRef(null);
  const quiz_guide_icon = useRef(null);
  const clearSetInterval = useRef(null);
  const answerResponseTimeStart = useRef(null);
  const answerResponseTimeEnd = useRef(null);
  const topicSetInterval = useRef(null);
  const threeMinutesTimer = useRef(0);
  let global_resource_id = useRef(null);
  var transition_next_quiz = useRef(true);
  var global_answer_type = useRef(null);
  const question_filter_content = useRef(null);
  const quiz_status_content = useRef(null);
  const question_counter_helptext = useRef(null);
  const question_x_id = useRef(null);
  const lastThreeQuestionIdArray = useRef([]);
  const conceptArrayDuplicate = useRef([]);
  const feedback_see_ans_btn = useRef(null);
  const pdf_section_container = useRef();
  const currentExamspaceData =  useRef({});
  const continuousPollFnIntervalIdList =  useRef([]);
  const currentExamspaceDataRef = useRef({});
  // both chapters and topics data are stored in this variable
  const selectedTopicTypeData = useRef([]);

  let initialX = null;
  let delay_new = 4000;

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  // PDF Navigation
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
    GoToNextPage,
    GoToFirstPage,
  } = pageNavigationPluginInstance;
  const fileUploadRef = useRef(null);
  let questionStatusNameMapping = {
		"correct": "Correct",
		"wrong": "Wrong",
		"unanswered": "New",
		"reread": "Refresh"
	}

  // Quiz Filter counter icon
  const [quizFilterCounter, setQuizFilterCounter] = useState(0);
  const quizFilterCounterRef = useRef(0);

  useEffect(() => {
    localStorage.setItem("defaultTopicType", "chapters")
    proxyCallGetDefaultExamspaceIdUtility();
    const handleResize = () => {
      set_window_width(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
        // continuousPollFnIntervalIdList.current.forEach((intervalId)=>{
        //   clearInterval(intervalId);
        // });
        clearPollFunctionIntervals()
      localStorage.removeItem("current_question_content");
      window.removeEventListener("resize", handleResize);
    };
  }, []);


useEffect(() => {
  answerResponseTimeStart.current = new Date();
}, [current_question_content]);

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === "Tab" || event.key === "ArrowRight") {
      if (allownext && !pdfopen) {
        if (progressBarContainer.current) {
          progressBarContainer.current.style.visibility = "hidden";
        }
        if (progressBarContainer.current) {
          if(window.innerHeight > 1000){
            progressBarContainer.current.style.transition = "all 0s ease";
          } else {
            progressBarContainer.current.style.transition = "all 1s ease";
          }
          progressBarContainer.current.style.transform = `translateY(150%)`;
        }
        let options_node_list = document.getElementsByClassName(
          "mcq-new-option-card"
        );
        // reset options color
        Array.from(options_node_list).forEach((element) => {
          element.style.backgroundColor = "#fff";
        });
        question_id_redirect();
      }
    }
  };

  document.addEventListener("keydown", handleKeyDown);

  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
}, [allownext, pdfopen]);

useEffect(() => {
  function handleClickOutside(event) {
    if (
      pdfloaded &&
      pdf_section_container.current &&
      !pdf_section_container.current.contains(event.target)
    ) {
      handleClosePdf();
    }
  }

  document.addEventListener("click", handleClickOutside);
  return () => {
    document.removeEventListener("click", handleClickOutside);
  };
}, [pdfloaded]);

/* 
When reread btn is clicked, if the document is fetched 1st time we get it from /reread-pdf api
once the document is fetched it's stored in Indexed DB, to reduce api call and loading time.
The beloow functions are used to store and retrieve documents from Indexed DB:
indexeddbAddData(), indexeddbGetData(),  indexeddbUpdateData()
*/
function indexeddbAddData(inputData){
  try{
    let db;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onsuccess = function(event) {
      db = event.target.result;
      let transaction = db.transaction(["table1"], "readwrite");
      let table1 = transaction.objectStore("table1");
      let response1 =  table1.add({id: 100, data: inputData});
      response1.onsuccess = function(event) {
      };
      response1.onerror = function(event) {
        toast.error("indexedDB error add data", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
        // console.error("Error adding data: " + event.target.errorCode);
      };
    };
    request.onerror = function(event) {
      toast.error("indexedDB error", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      // console.error("indexedDB Error" + event.target.errorCode);
    };
  } catch (error) {
    console.error(error)
  }
}
function indexeddbGetData() {
  return new Promise( (resolve, reject) => {
    let db;
    let output = undefined;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onsuccess = function(event) {
      db = event.target.result;
      let transaction = db.transaction(["table1"], "readwrite");
      let table1 = transaction.objectStore("table1");
      let response1 =  table1.get(100);

      response1.onsuccess = function(event) {
        resolve(event.target.result);
      };
      response1.onerror = function(event) {
        toast.error("indexedDB error get data", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
        console.error("indexeddb Error get data : " + event.target.errorCode);
      };
    };
    request.onerror = function(event) {
      toast.error("indexedDB error", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.error("indexedDB error" + event.target.errorCode);
    };
  });
}
function indexeddbUpdateData(inputData) {
  let db;
  let request = indexedDB.open("dendeLocalDB", 1);
  request.onsuccess = function(event) {
    db = event.target.result;
    let transaction = db.transaction(["table1"], "readwrite");
    let table1 = transaction.objectStore("table1");
    let response1 =  table1.put({id: 100, data: inputData});

    
    response1.onerror = function(event) {
      toast.error("indexedDB error update data", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.error("indexedDB error update data : " + event.target.errorCode);
    };
  };
  
  request.onerror = function(event) {
    toast.error("indexedDB error update data", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
    });
    console.error("indexedDB error: " + event.target.errorCode);
  };
}

// Get current examspace data
async function proxyCallGetDefaultExamspaceIdUtility(){
  let response = await getDefaultExamspaceIdUtility();
  if(localStorage.getItem("defaultTopicType") !== null){
    const urlParams = new URLSearchParams(window.location.search);
    if ((searchParams.get("questionId") && searchParams.get("redirect")) && (urlParams.get("redirect") === "reread" || urlParams.get("redirect") === "library")){
      set_chapters_topics_btn("chapters")
      chapters_topics_btn_value.current = "chapters"
    } else {
      if(Object.keys(sharedUserInfo).length > 0 && sharedUserInfo.plan_type === "free" && sharedUserInfo.payment_history_count > 0){
        set_chapters_topics_btn("chapters")
        chapters_topics_btn_value.current = "chapters"
      } else {
        if(localStorage.getItem("defaultTopicType") === "topics"){
          set_chapters_topics_btn("topics")
          chapters_topics_btn_value.current = "topics"
        }
      }
    }
  }  
  initializePage()
}

async function getDefaultExamspaceIdUtility(){
  return new Promise( async(resolve, reject) => {
    let default_examspace_id = "";
    if( Object.keys(sharedUserInfo).length > 0){
      default_examspace_id = sharedUserInfo.default_examspace_id;
      global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
      resolve(default_examspace_id);
    } 
    else {
      let getResponse = await GetUserInfoAPI()
      if (getResponse.status) {
        if(getResponse.data.length > 0){
          if(Object.keys(getResponse.data[0]).length > 0){
            setSharedUserInfo(getResponse.data[0]);
            default_examspace_id = getResponse.data[0]["default_examspace_id"];
            global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
            resolve(default_examspace_id);
          }
        }
      }
    }
    
  });
}

async function getTopicData({topic_type}){
  console.log('[2] getTopicData function called.');
  if(topic_type === "chapters"){
    let apiResponse = await getAllConcepts(global_default_examspace_id.current);
    if(apiResponse.data && apiResponse.data.length > 0){
      // set_concepts_data(apiResponse.data);
      selectedTopicTypeData.current = apiResponse.data;
      return true;
    } else {
      return false;
    }
  } else if (topic_type === "topics"){
    let apiResponse = await getNewTopicsAPI(global_default_examspace_id.current);
    if(apiResponse.data && apiResponse.data.length > 0){
      // set_concepts_data(apiResponse.data);
      selectedTopicTypeData.current = apiResponse.data;
      return true;
    } else {
      return false;
    }
  }
}

async function initializePage(){
  console.log('[1.1] initializePage function called.');
  let doesTopicDataAvailable = await getTopicData({topic_type:chapters_topics_btn_value.current});
  // for quiz filters component
  let examspaceAPIResponse = await getAllExamSpaces();
  currentExamspaceDataRef.current = examspaceAPIResponse.data.filter((examspace) => examspace.id === global_default_examspace_id.current)[0];
  if(currentExamspaceDataRef.current.resources.length > 0){
    // decide wheather to show Quiz UI or loading UI
    set_resource_available(true);
    if(doesTopicDataAvailable){
      await initialQuizFilterSelection();  
      let questionRequestJson = undefined; 
      if(chapters_topics_btn_value.current === "chapters"){
        questionRequestJson = await resquestBodyBuilderForChapters();
      } else if (chapters_topics_btn_value.current === "topics"){
        console.log('resquestBodyBuilderForTopics 2');
        questionRequestJson = await resquestBodyBuilderForTopics();
      }
      const requestPayload = {
        "last_three_question_ids": "question_id" in questionRequestJson ? [] : lastThreeQuestionIdArray.current,
        ...questionRequestJson
      };
      console.log('[1.2] value of get-question api payload before sending', requestPayload);
      let questionAPIResponse = await getQuestionUtility(requestPayload);
      if(questionAPIResponse && questionAPIResponse.data.length > 0){
        console.log('quizFilterRef.current before 1', quizFilterRef.current);
        processGetQuestionAPIResponse(questionAPIResponse)
        // continuousPollFnIntervalIdList.current.forEach((intervalId)=>{
        //   clearInterval(intervalId);
        // }); 
        clearPollFunctionIntervals()
        set_resource_available(true);
        set_question_available(true);
        // set_data_available(true);
      } else {
        set_resource_available(true);
        set_question_available(false);
        // set_data_available(false);
        if(continuousPollFnIntervalIdList.current.length <= 0){
          continuousPollFucntion()
        }
      }
    } else {
      set_resource_available(true);
      set_question_available(false);
      if(continuousPollFnIntervalIdList.current.length <= 0){
        continuousPollFucntion()
      }
    }
  } else {
    set_resource_available(false);
    set_question_available(false);
    // set_data_available(false);
  }
}


function continuousPollFucntion(){
  let timeDuration  = 0;
  let tempIntervalId = setInterval(async ()=>{
      timeDuration += 20;
      if(timeDuration >= 600){
        // continuousPollFnIntervalIdList.current.forEach((intervalId)=>{
        //   clearInterval(intervalId);
        // });  
        clearPollFunctionIntervals()                  
      }
      conceptsAndGetQuestionAPICall();
  }, 20000);
  continuousPollFnIntervalIdList.current.push(tempIntervalId);
}

function processGetQuestionAPIResponse(question_response){
  setCurrentTopicData(question_response.data[0]["topic_info"])

  set_question_available(true);
  set_data_available(true);

  set_current_question_content(question_response.data);
  setQuestionData(question_response.data);

  set_option_card_clickable(true);
  set_quiz_option_clicked(false);

  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  localStorage.setItem(
    "current_question_content",
    JSON.stringify(question_response.data)
  );
  localStorage.removeItem("question_seen_count");
  localStorage.removeItem("total_question_count");
  localStorage.setItem(
    "question_seen_count",
    question_response.data[0]["seen_question_count"]
  );
  localStorage.setItem(
    "total_question_count",
    question_response.data[0]["total_question_count"]
  );

  setAllowNext(false);
  console.log('quizFilterRef.current before 2', quizFilterRef.current);
}

async function getQuestionAPICall(){
  console.log('[11.1] getQuestionAPICall function called.')
  let questionRequestJson = undefined; 
  if(chapters_topics_btn_value.current === "chapters"){
    questionRequestJson = await resquestBodyBuilderForChapters();
  } else if (chapters_topics_btn_value.current === "topics"){
    console.log('resquestBodyBuilderForTopics 1');
    questionRequestJson = await resquestBodyBuilderForTopics();
  }
  const requestPayload = {
    "last_three_question_ids": "question_id" in questionRequestJson ? [] : lastThreeQuestionIdArray.current,
    ...questionRequestJson
  };
  let questionAPIResponse = await getQuestionUtility(requestPayload);  
  if (questionAPIResponse && questionAPIResponse.data.length > 0) {
    processGetQuestionAPIResponse(questionAPIResponse);
    clearURLParamsUtility();
    // continuousPollFnIntervalIdList.current.forEach((intervalId)=>{
    //   clearInterval(intervalId);
    // });
    clearPollFunctionIntervals();
  } else {
    set_question_available(false);
    set_data_available(false);
  }
}

function clearPollFunctionIntervals(){
  console.log('clearPollFunctionIntervals called - continuousPollFnIntervalIdList.current:', continuousPollFnIntervalIdList.current)
  continuousPollFnIntervalIdList.current.forEach((intervalId)=>{
    console.log('clearPollFunctionIntervals - clearing interval id:', intervalId)
    clearInterval(intervalId);
    continuousPollFnIntervalIdList.current = continuousPollFnIntervalIdList.current.filter((id)=>id !== intervalId);
  });
}

async function conceptsAndGetQuestionAPICall(){
  let doesTopicDataAvailable = await getTopicData({topic_type:chapters_topics_btn_value.current});
  // let conceptsAPIResponse = await getAllConcepts(global_default_examspace_id.current);
  if(doesTopicDataAvailable){
    set_resource_available(true);
    set_question_available(false);
    // for quiz flter component
    await initialQuizFilterSelection();
    let questionRequestJson = undefined; 
    if(chapters_topics_btn_value.current === "chapters"){
      questionRequestJson = await resquestBodyBuilderForChapters();
    } else if (chapters_topics_btn_value.current === "topics"){
      console.log('resquestBodyBuilderForTopics 3');
      questionRequestJson = await resquestBodyBuilderForTopics();
    }
    const requestPayload = {
      "last_three_question_ids": "question_id" in questionRequestJson ? [] : lastThreeQuestionIdArray.current,
      ...questionRequestJson
    };
    console.log('16 - value of get-question api payload before sending', requestPayload)
    let questionAPIResponse = await getQuestionUtility(requestPayload);  
    if (questionAPIResponse && questionAPIResponse.data.length > 0) {
      processGetQuestionAPIResponse(questionAPIResponse);
      // clear all intervals
      // continuousPollFnIntervalIdList.current.forEach((intervalId)=>{
      //   clearInterval(intervalId);
      // });
      clearPollFunctionIntervals()
      // removing loading gif
      set_resource_available(true);
      set_question_available(true);
    } else {
      // enable loading gif
      set_resource_available(true);
      set_question_available(false);
    }
  } else {
    set_resource_available(true);
    set_question_available(false);
  }
}

  function clearURLParamsUtility(){
    searchParams.forEach((value, key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
    console.log('clearURLParamsUtility function called, url params cleared', searchParams)
  }

  function resquestBodyBuilderForChapters() {
    return new Promise((resolve, reject) => {
      console.log('[6.1] - resquestBodyBuilderForChapters function called')
      let responseObj = {};
      responseObj["topic_type"] = "chapters";
      responseObj["examspace_id"] = global_default_examspace_id.current;
      if(enableSuffleModeRef.current === true){
        console.log('111111111111111111111111111111111111111111111111111')
        console.log('searchParams.get("questionId")', searchParams.get("questionId"))
        console.log('111111111111111111111111111111111111111111111111111')
        if (searchParams.get("questionId")) {
          responseObj["question_id"] = [searchParams.get("questionId")]
        } else {
          let topic_id_list = [];
          quizFilterRef.current.forEach((resource) => {
            resource.topic_list.forEach((topic) => {
              if (topic.is_locked === false && topic.selected === true) {
                topic_id_list.push(topic.topic_id); // Add the topic ID if it's selected
              }
            });
          });
          console.log('[6.4] - since some topics are not selected, sending topic_id_listt', topic_id_list)
          responseObj["topic_id"] = topic_id_list;        
        }
      } else {
        if (searchParams.get("questionId")) {
          responseObj["question_id"] = [searchParams.get("questionId")]
        } else if (searchParams.get("topicId")) {
          responseObj["topic_id"] = [searchParams.get("topicId")]
          // 1st unselect all the topics
          quizFilterRef.current.forEach((resource) => {
            handleSelectionChapters({type:"resource", resourceId:resource.resource_id, trigger:"ui", override:false});
          });  
          // 2nd select the particular topic
          const selectedTopicResourceId = quizFilterRef.current.find(resource => 
            resource.topic_list.some(topic => topic.topic_id === searchParams.get("topicId"))
          )?.resource_id || null;          
          handleSelectionChapters({type:"topic", resourceId:selectedTopicResourceId, topicId:searchParams.get("topicId"), trigger:"ui", override:true});    
          clearURLParamsUtility()
        } else if (searchParams.get("resourceId")) {
          responseObj["resource_id"] = [searchParams.get("resourceId")]
          handleSelectionChapters({type:"resource", resourceId:searchParams.get("resourceId"), trigger:"ui", override:true});
          clearURLParamsUtility()
        } else {
          // check if any topic is not selected
          let doesAllTopicSelected = !quizFilterRef.current.some((resource) => 
            resource.topic_list.some(topic => topic.is_locked === false && topic.selected === false)
          );          
          console.log('[6.3] checking all topics are selected', doesAllTopicSelected)

          if(doesAllTopicSelected){
            // Since all topics are selected, send only the resource Ids
            let resource_id_list = quizFilterRef.current.map(key => key.resource_id);
            console.log('[6.4] - since all topics are selected, sending only resource_id_list', resource_id_list)
            responseObj["resource_id"] = resource_id_list
          } else {
            let topic_id_list = [];
            // If not all topics are selected, we need to send the topic Ids
            quizFilterRef.current.forEach((resource) => {
              resource.topic_list.forEach((topic) => {
                if (topic.is_locked === false && topic.selected === true) {
                  topic_id_list.push(topic.topic_id); // Add the topic ID if it's selected
                }
              });
            });
            console.log('[6.4] - since some topics are not selected, sending topic_id_listt', topic_id_list)
            responseObj["topic_id"] = topic_id_list;
        }
      }
    }
      console.log('[6.5] - value of responseObj', responseObj)
      resolve(responseObj);
    });
  }
  function resquestBodyBuilderForTopics() {
    return new Promise((resolve, reject) => {
      console.log('[6.1] - resquestBodyBuilderForTopics function called')
      let responseObj = {};
      responseObj["topic_type"] = "topics";
      responseObj["examspace_id"] = global_default_examspace_id.current
      if(enableSuffleModeRef.current === true){
        if (searchParams.get("questionId")) {
          console.log('[6.4] question id in url present', searchParams.get("questionId"));
          responseObj["question_id"] = [searchParams.get("questionId")]
          responseObj["topic_id"] = [searchParams.get("topic_id")]
        } else {
          let topic_id_list = quizFilterRef.current.map(key => key.topic_id);
          console.log('[6.3] - since shuffle mode is on, sending all topic_id_list', topic_id_list)
          responseObj["topic_id"] = topic_id_list
        }        
      } else {
        if (searchParams.get("questionId")) {
          console.log('[6.4] question id in url present', searchParams.get("questionId"));
          responseObj["question_id"] = [searchParams.get("questionId")]
          responseObj["topic_id"] = [searchParams.get("topic_id")]
        } else if (searchParams.get("topicId")) {
          console.log('[6.5] topicId in url present', searchParams.get("topicId"));
          responseObj["topic_id"] = [searchParams.get("topicId")]
          // 1st unselect all the topics
          quizFilterRef.current.forEach((topic) => {
          handleSelectionTopics({topicId:topic.topic_id, trigger:"ui", override:false});
          });  
          // 2nd select the particular topic
          handleSelectionTopics({topicId:searchParams.get("topicId"), trigger:"ui", override:true});      
          clearURLParamsUtility()
        } else {
          let topic_id_list = [];
          quizFilterRef.current.forEach((topic)=>{
            if(topic.is_locked === false && topic.selected === true){
              topic_id_list.push(topic.topic_id);
            }
          });
          responseObj["topic_id"] = topic_id_list;
      }
    }
      console.log('[6.7] - value of responseObj', responseObj)
      resolve(responseObj);
    });
  }

  function storeLastThreeQuestionId(id) { 
    if (lastThreeQuestionIdArray.current.length >= 3) {
      lastThreeQuestionIdArray.current.pop();
      lastThreeQuestionIdArray.current.unshift(id);
    } else {
      lastThreeQuestionIdArray.current.unshift(id);
    }
  }

  function handleUploadSuccess(response){
    // conceptsAndGetQuestionAPICall();
    initializePage();
  }

  function triggerFileUpload() {
    fileUploadRef.current.triggerFileInput();
  }

  function handleMarketingDataFirstQuestionAnswered(){
    try{
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "First_Question_Replied",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch(error) {
      console.error('quiz page', error)
    }
  }

  function handleMarketingDataConceptCompleted(){
    try{
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Concept_completed",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch(error) {
      console.error('quiz page', error)
    }    
  }

  function save_answer_to_db(answer_is_correct, clicked_option) {
    storeLastThreeQuestionId(current_question_content[0]["question_id"]);

    let temp_response_ms =
      answerResponseTimeEnd.current.getTime() -
      answerResponseTimeStart.current.getTime();

    let qAns;

    var getAns = current_question_content[0].question_content.options;
    for (let i = 0; i < getAns.length; i++) {
      const element = getAns[i];
      if (element.is_correct) {
        qAns = element.text;
      }
    }

    let body = {
      question_id: current_question_content[0]["question_id"],
      topic_id: current_question_content[0]["topic_info"]["topic_id"],
      is_correct: answer_is_correct,
      response_ms: temp_response_ms,
      question_name: current_question_content[0].question_content["question"],
      complexity_score: 2.5,
      question_answer: qAns,
      resource_id: current_question_content[0]["topic_info"]["resource_id"],
      is_answered: current_question_content[0]["is_answered"],
      question_current_answer: clicked_option.text,
    };

    let isanswered_first_question = JSON.parse(localStorage.getItem("eQuestionFlag"));
    if (isanswered_first_question === false) {
      body["isanswered_first_question"] = true;
      localStorage.setItem("eQuestionFlag", true);
      handleMarketingDataFirstQuestionAnswered();
      // let temp_user_id = parseInt(JSON.parse(localStorage.getItem("userId")));
      // // CreateGtagEvent("first_questions", {
      // //   user_id: temp_user_id,
      // // });
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    };
    fetch(`${apiUrl}/answer`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          const urlParams = new URLSearchParams(window.location.search);
          var keyOfObj;
          var valueOfObj;
          // urlParams.forEach((value, key) => {
          //   keyOfObj = key;
          //   valueOfObj = value;
          // });
          if (urlParams.get("question_id") !== null) {
            keyOfObj = "question_id";
            valueOfObj = urlParams.get("question_id");
          } else if (urlParams.get("topic_id") !== null) {
            keyOfObj = "topic_id";
            valueOfObj = urlParams.get("topic_id");
          } else if (urlParams.get("resource_id") !== null) {
            keyOfObj = "resource_id";
            valueOfObj = urlParams.get("resource_id");
          } else {
            keyOfObj = "filter_status";
            valueOfObj = "off";
          }

          if (response.data.topic_score_display_60_percent) {
            navigate(
              `/topic-completed?${keyOfObj}=${valueOfObj}&score=${response.data.topic_score}&title=${response.data.topic_name}&topic_id=${response.data.topic_id}&percentage=60`
            );
          }
          if (response.data.topic_score_display_100_percent) {
            handleMarketingDataConceptCompleted()
            navigate(
              `/topic-completed?${keyOfObj}=${valueOfObj}&score=${response.data.topic_score}&title=${response.data.topic_name}&topic_id=${response.data.topic_id}&percentage=100`
            );
          }
          if (response.data.first_three_questions_display) {
            let data = {
              show: response.data.first_three_questions_display,
              conceptId: response.data.topic_id,
            };
            set_first_three_questions_display(data);
          }
        }
      });
  }

  function setFeedbackBoxPosition(){
    if(window.innerHeight > 1000){
      const rect = quizOptionsContainer.current.getBoundingClientRect();
      const bottomY = rect.bottom + window.scrollY + 50;
      progressBarContainer.current.style.removeProperty('bottom');
      progressBarContainer.current.style.top = `${bottomY}px`;
    } else{
      progressBarContainer.current.style.removeProperty('top');
      progressBarContainer.current.style.bottom = "10px";
    }
  }

  function optionClickHandler(selected_option, option_json) {
    progressBar.current.style.opacity = "1";
    set_show_answer_in_feedback(false);

    setAllowNext(true);
    set_option_card_clickable(false);
    global_answer_type.current = option_json["is_correct"];
    answerResponseTimeEnd.current = new Date();

    let options_node_list = document.getElementsByClassName(
      "mcq-new-option-card"
    );
    // reset options color
    Array.from(options_node_list).forEach((element) => {
      element.style.backgroundColor = "#fff";
    });

    let temp_quiz_guide_text_show_count = localStorage.getItem(
      "quiz_hints_guide_text_show_count"
    );
    temp_quiz_guide_text_show_count =
      parseInt(temp_quiz_guide_text_show_count) + 1;
    localStorage.setItem(
      "quiz_hints_guide_text_show_count",
      temp_quiz_guide_text_show_count
    );
    // guide text visible
    let quiz_guide_text_show_count = localStorage.getItem(
      "quiz_hints_guide_text_show_count"
    );

    if (quiz_guide_text_show_count > 2) {
      quiz_guide_text.current.style.display = "none";
      quiz_guide_icon.current.style.display = "none";
    }
    setFeedbackBoxPosition()

    // display quiz tips
    progressBarContainer.current.style.visibility = "visible";
    progressBarContainer.current.style.transform = "translateY(0%)";
    // transform

    // progressBar.current.style.animation= `slide 4s forwards`;

    // conditional baed style for options
    if (option_json["is_correct"] === true) {
      setAllowNext(true);
      //option color
      let quizOptionCard = document.getElementById(selected_option);
      quizOptionCard.style.backgroundColor = "var(--light-green)";
      // selected_option.style.backgroundColor = "var(--light-green)";
      // quiz tips title
      quiz_hints_title.current.innerHTML = "Correct!";
      //progress bar color
      progressBar.current.style.backgroundColor = "var(--light-green)";
      // see correct answer button
      feedback_see_ans_btn.current.style.display = "none";

      let feedbackMenuContain = document.getElementsByClassName(
        "feedback-menu-container"
      );
      if (feedbackMenuContain.length) {
        feedbackMenuContain[0].style.justifyContent = "center";
      }

      let verticalLine = document.getElementById("vertical-line");
      if (verticalLine) {
        verticalLine.style.display = "none";
      }
    } else if (option_json["is_correct"] === false) {
      setAllowNext(true);
      //option color
      let quizOptionCard = document.getElementById(selected_option);
      quizOptionCard.style.backgroundColor = "var(--light-orange)";
      // selected_option.style.backgroundColor = "var(--light-orange)";
      // quiz tips title
      quiz_hints_title.current.innerHTML = "Not quite";
      //progress bar color
      progressBar.current.style.backgroundColor = "var(--light-orange)";
      // see correct answer button
      feedback_see_ans_btn.current.style.display = "flex";
      let verticalLine = document.getElementById("vertical-line");
      if (verticalLine) {
        verticalLine.style.display = "block";
      }
      let feedbackMenuContain = document.getElementsByClassName(
        "feedback-menu-container"
      );
      if (feedbackMenuContain.length) {
        feedbackMenuContain[0].style.justifyContent = "center";
      }
    }

    // quiz tips explanation
    quiz_hints_content.current.innerHTML = option_json["explanation"];
    save_answer_to_db(option_json["is_correct"], option_json);

    clearSetInterval.current = setTimeout(() => {
      if (transition_next_quiz.current === true) {
        Array.from(options_node_list).forEach((element) => {
          // element.style.backgroundColor = "#fff";
        });

        if (progressBarContainer.current) {
          // progressBarContainer.current.style.transform= `translateY(70%)`;
        }

        if (progressBarContainer.current) {
          // progressBarContainer.current.style.visibility = "hidden";
        }
        if (progressBar.current) {
          progressBar.current.style.animation = `none`;
        }

        question_count.current += 1;
        // if question_id present redirect to home
        // const urlParams = new URLSearchParams(window.location.search);
        // if (urlParams.get('question_id') !== null){
        //   if(urlParams.get('library') !== null){
        //     navigate("/library");
        //   } else if(urlParams.get('concept_topic_id') !== null){
        //     let topic_id = urlParams.get('concept_topic_id');
        //     navigate(`/conceptquestion?topic_id=${topic_id}`);
        //   } else{
        //     navigate("/home");
        //   }
        // }
        // var keyOfObj;
        // var valueOfObj;
        // urlParams.forEach((value, key) => {
        //   keyOfObj = key;
        //   valueOfObj = value;
        // });
        // if (keyOfObj === "question_id") {
        //   navigate("/home");
        //   return;
        // }
      }
    }, delay_new);
  }


  const validateNextQues = () => {
    console.log('validateNextQues function called, move to next question');
    if (allownext) {
      if (progressBarContainer.current) {
        progressBarContainer.current.style.visibility = "hidden";
      }
      if (progressBarContainer.current) {
        if(window.innerHeight > 1000){
          progressBarContainer.current.style.transition = "all 0s ease";
        } else {
          progressBarContainer.current.style.transition = "all 1s ease";
        }
        progressBarContainer.current.style.transform = `translateY(150%)`;
      }
      let options_node_list = document.getElementsByClassName(
        "mcq-new-option-card"
      );
      // reset options color
      Array.from(options_node_list).forEach((element) => {
        element.style.backgroundColor = "#fff";
      });
      question_id_redirect();

      // move_to_next_quiz();
    }
  };

  function question_id_redirect() {
    console.log('question_id_redirect function called, redirecting to library or concept question');
    const urlParams = new URLSearchParams(window.location.search);
    if (searchParams.get("questionId") && searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "library") {
        navigate("/library?questionId=" + searchParams.get("questionId"));
        return;
      } else if (searchParams.get("redirect") === "conceptQuestion") {
        if(searchParams.get("topicType") === "chapters"){
          navigate(`/conceptquestion?topic_type=${searchParams.get("topicType")}&topic_id=${searchParams.get("topic_id")}&resource_id=${searchParams.get("resource_id")}&questionId=${searchParams.get("questionId")}`);
          return;
        } else if (searchParams.get("topicType") === "topics"){
          navigate(`/conceptquestion?topic_type=${searchParams.get("topicType")}&topic_id=${searchParams.get("topic_id")}&examspace_id=${searchParams.get("examspace_id")}&questionId=${searchParams.get("questionId")}`);
          return;
        }
      } else if (urlParams.get("redirect") === "reread") {
        if (searchParams.get("questionId") && searchParams.get("page")) 
          navigate(`/reread?focus=${searchParams.get("questionId")}&page=${searchParams.get("page")}`);
          return;
      } else {
          navigate("/reread");
          return;
      }
      clearURLParamsUtility();
    } else {
        clearURLParamsUtility();
        getQuestionAPICall();
    }
  }

  const handleTouchStart = (e) => {
    initialX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (initialX === null) return;
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - initialX;

    if (deltaX < 0) {
      if (allownext) {
        validateNextQues();
      }
    }

    initialX = null;
  };

  //Get PDF  file from server and display it in the PDF Viewer
  async function handleClickReread(data) {
    setPdfOpen(true);
    setLoaderStatus(true);
    // read blob data present in the indexedDB
    // if present in indexedDB load from local data
    let doesLocalBlobPresent = false;
    let localBlobData;
    let tempFileName1 = data.resource_url.split("/");
    let tempFileName2 = tempFileName1[tempFileName1.length - 2];
    let blobArray = await indexeddbGetData();
    if(blobArray){
      let tempArray = blobArray.data;
      let foundItem = tempArray.filter(item => item["resourceId"] === tempFileName2);
      if(foundItem.length > 0){
        doesLocalBlobPresent = true;
        localBlobData = foundItem[0]["fileBlob"];
      }
    }
    if(doesLocalBlobPresent){
      // Open pdf reader popup
      let pdfContainerQuiz = document.getElementsByClassName("pdf-container-quiz");
      if (pdfContainerQuiz.length) {
        // containerRef.current.style.opacity = "0.2";
        // progressBarContainer.current.style.opacity = "0.2";
        pdfContainerQuiz[0].style.transform = "translateX(0)";
        pdfContainerQuiz[0].style.visibility = "visible";
      }
      // set pdf url from blob
      setLoaderStatus(false);
      setpdf(URL.createObjectURL(localBlobData));
      setPageNumber(data.matching_page - 1);
      setTimeout(() => {
        setPdfLoaded(true);
      }, 2500);
    } else {
      const body = { resource_uri: data.resource_url };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      fetch(`${apiUrl}/reread-pdf`, requestOptions)
        .then((response) => response.blob())
        .then(async (response) => {
          let tempRes = response;
          // Open pdf reader popup
          let pdfContainerQuiz = document.getElementsByClassName("pdf-container-quiz");
          if (pdfContainerQuiz.length) {
            // containerRef.current.style.opacity = "0.2";
            // progressBarContainer.current.style.opacity = "0.2";
            pdfContainerQuiz[0].style.transform = "translateX(0)";
            pdfContainerQuiz[0].style.visibility = "visible";
          }
          // set pdf url from blob
          setLoaderStatus(false);
          setpdf(URL.createObjectURL(response));
          setPageNumber(data.matching_page - 1);
          setTimeout(() => {
            setPdfLoaded(true);
          }, 2500);
          // store blob data to indexedDB
          let tempFileName1 = data.resource_url.split("/");
          let tempFileName2 = tempFileName1[tempFileName1.length - 2]
          let blobArray = await indexeddbGetData();
          if(blobArray){
            let tempArray = blobArray.data;
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            indexeddbUpdateData(tempArray);
          } else {
            let tempArray = [];
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            indexeddbAddData(tempArray);
          }
        });
    }

  }
  function handleClosePdf() {
    setPdfOpen(false);
    // containerRef.current.style.opacity = "1";
    // progressBarContainer.current.style.opacity = "1";
    let pdfContainerQuiz =
      document.getElementsByClassName("pdf-container-quiz");
    if (pdfContainerQuiz.length) {
      pdfContainerQuiz[0].style.transform = "translateX(100%)";
      setTimeout(() => {
        pdfContainerQuiz[0].style.visibility = "hidden";
        setPdfLoaded(false);
      }, 600);
    }
  }

  // Function to be called after 2 seconds
  const delayedFunction = (event) => {
    if (
      progressBarContainer.current &&
      !progressBarContainer.current.contains(event.target)
    ) {
      if (window.innerWidth < 600 && allownext) {
        // validateNextQues();
      }
    }
    // Your logic here
  };

  // Event handler for touch start
  const handleTouchStartPDF = (event) => {
    // Start the timer
    const id = setTimeout(delayedFunction(event), 1000);
    setTimerId(id);
  };

  // Event handler for touch move
  const handleTouchMovePDF = () => {
    // If touch move is triggered, clear the timer
    clearTimeout(timerId);
  };

  async function getTopicQuestionsUtility({topicData=null}){
    console.log('topicData', topicData);
    if (!show_topic_card_questions){
      // let apiResponse = await getConceptQuestionAPI({resourceId:resourceId, topicId:topicId});
      let apiResponse = null;
      if(chapters_topics_btn_value.current === "chapters"){
        let requestJson = {
          "topic_type": chapters_topics_btn_value.current,
          "topic_id": topicData.topic_id,
          "resource_id": topicData.resource_id
        }
        console.log('requestJson', requestJson);
        apiResponse = await getQuestionListAPI(requestJson);
      } else if (chapters_topics_btn_value.current === "topics"){
        let requestJson = {
          "topic_type": chapters_topics_btn_value.current,
          "topic_id": topicData.new_topic_id,
          "examspace_id": global_default_examspace_id.current
        }
        apiResponse = await getQuestionListAPI(requestJson);
      }
      setTopicQuestionArray(apiResponse.data.question_list)
    }
  }
	function displayCurrentTopicName(topicName){
    if(window_width >= 1000 && window_width <= 1100){
      return `${topicName.substring(0, 30)}...`;
    } else {
      if(topicName.length <= 35){
        return topicName;
      } else {
        return `${topicName.substring(0, 35)}...`;
      }
    }
	}
	function displayTooltipForCurrentTopicName(topicName){
    if(window_width >= 1000 && window_width <= 1100){
      return true;
    } else {
      if(topicName.length <= 35){
        return false;
      } else {
        return true;
      }      
    }
	}
  function displayProgessBar({correct=0, unseen=0, wrong=0, reread=0}){
    if(correct === 0 && unseen === 0 && wrong === 0 && reread === 0){
      unseen = 100;
    }
    return(
        <>
            <div className={style["topic-card-progress-bar"]}>
              {
                correct > 0 
                ?
                  <div 
                      style={{width:`${correct}%`, backgroundColor:"var(--light-green)"}}
                      className={`${style["progress-bar-child"]}`}>
                        <small className={style["global-tooltip"]}>{questionStatusNameMapping["correct"]}</small>
                  </div>
                :
                  <></>
              }
              {
                wrong > 0 
                ?
                  <div 
                      style={{width: `${wrong}%`, backgroundColor:"var(--light-orange)"}}
                      className={`${style["progress-bar-child"]}`}>
                        <small className={style["global-tooltip"]}>{questionStatusNameMapping["wrong"]}</small>
                  </div>
                :
                  <></>
              }
              {
                reread > 0 
                ?
                  <div 
                      style={{width: `${reread}%`, backgroundColor:"var(--refresh-violet)"}}
                      className={`${style["progress-bar-child"]}`}>
                        <small className={style["global-tooltip"]}>{questionStatusNameMapping["reread"]}</small>
                  </div>
                :
                  <></>
              }
              {
                unseen > 0 
                ?
                  <div 
                      style={{width: `${unseen}%`, backgroundColor:"var(--light-blue)"}}
                      className={`${style["progress-bar-child"]}`}>
                        <small className={style["global-tooltip"]}>{questionStatusNameMapping["unanswered"]}</small>
                  </div>
                :
                  <></>
              }
            </div>
        </>
    )
  }
  function getQuestionStatusColor(status){
    if (status === "correct"){
      return "var(--light-green)";
    } else if(status === "wrong"){
      return "var(--light-orange)";
    } else if (status === "unanswered"){
      return "var(--light-blue)";
    } else if (status === "reread"){
      return "var(--refresh-violet)";
    }
  }
  function addBoxShadowForQuestionStatus(status){
    return "var(--global-box-shadow)";
  }
  function checkFileNameLength(name){
    if(window_width < 400){
      return `${name.substring(0, 20)}..`;
    } else if(window_width >= 1000 && window_width <= 1100){
      return `${name.substring(0, 20)}..`;
    } else {
      if(name.length <= 28){
          return name;
      } else {
          return `${name.substring(0, 28)}...`;
      }
    }
  }
  function displayTooltipForFileNameLength(name){
    if(window_width < 400){
      return true;
    } else if (window_width >= 1000 && window_width <= 1100) {
      return true;
    } else {
      if(name.length <= 28){
          return false;
      } else {
          return true;
      }
    }
  }
  function openQuizWithQuestionId({questionId="", topicId=""}){
    // reset the answer feedback box
    validateNextQues();
    if(chapters_topics_btn_value.current === "topics"){
      searchParams.set("questionId", questionId);
      searchParams.set("topic_id", topicId);
      setSearchParams(searchParams);
      getQuestionAPICall();
      set_show_topic_card_questions(false);
    } else {
      searchParams.set("questionId", questionId);
      setSearchParams(searchParams);
      getQuestionAPICall();
      set_show_topic_card_questions(false);
    }
  }
  function CurrentQuizTopicComponent(){ 
    // @note topic card
    return(
      <>
      {
        Object.keys(currentTopicData).length > 0
        ?
          <>
            <div 
              onClick={()=>{set_show_topic_card_questions(!show_topic_card_questions)}}
              style={show_topic_card_questions ? {display: "block"} : {display: "none"}}
              className={style["topic-card-question-overlay"]}>
            </div>  
            <div 
              className={style["topic-card"]}>
              <div 
                style={sharedUserInfo.plan_type === "free" ? {display: "flex"} : {display: "none"}}
                onClick={()=>{navigate("/pushmode")}}
                className={style["free-user-bar"]}>
                <p>Unlock all the questions and unlimited files upload</p>
              </div>
              <div className={style["topic-card-header"]}>
              
                <div className={style["topic-card-header-left"]}>
                  <h3>
                    {displayCurrentTopicName(currentTopicData.topic_name)}
                    {
                      displayTooltipForCurrentTopicName(currentTopicData.topic_name)
                      ?
                        <small className={style["global-tooltip"]}>{currentTopicData.topic_name}</small>
                      :
                        <></>
                    }
                  </h3>
                  {
                    currentTopicData.resource_name &&chapters_topics_btn === "chapters"
                    ?
                      <>
                        <div className={style["topic-card-header-desscription"]}>
                          <span>pages {currentTopicData.start_page} - {currentTopicData.end_page}</span>
                          <span className={style["horizontal-bar"]}>|</span>
                          <span>
                            {checkFileNameLength(currentTopicData.resource_name)}
                            {
                              displayTooltipForFileNameLength(currentTopicData.resource_name)
                              ?
                                <small className={style["global-tooltip"]}>{currentTopicData.resource_name}</small>
                              :
                                <></>
                            }
                          </span>
                        </div>
                      </>
                    :
                      <></>
                  }
                </div>
                <div className={style["topic-card-header-right"]}>       
                  {displayProgessBar({
                    correct: currentTopicData.num_correct_question,
                    unseen: currentTopicData.num_unseen_question,
                    reread: currentTopicData.num_reread_question,
                    wrong: currentTopicData.num_wrong_question})
                  }
                  <div 
                    onClick={(e)=>{
                      set_show_topic_card_questions(!show_topic_card_questions);
                      getTopicQuestionsUtility({topicData: currentTopicData});
                    }} className={style["topic-card-open-icon"]}>
                    <img
                      style={show_topic_card_questions === true ?
                        {transform: "rotate(180deg)"}
                        :
                        {transform: "rotate(0deg)"}
                      }
                    src={downArrowIcon} alt="open-icon"/>
                  </div>
                </div>
              </div>  
              <div
                style={show_topic_card_questions ? {display: "block"} : {display: "none"}}
                className={style["topic-card-question-list"]}>
                <div className={style["topic-question-header"]}>
                <div className={style["topic-question-header-left"]}>
                  <h2>Question</h2>
                </div>
                <div className={style["topic-question-header-right"]}>
                  <h2>Status</h2>
                </div>
                </div>
                {
                  topicQuestionArray.length > 0
                  ?
                    topicQuestionArray.map((topicQuestionItem)=>(
                      <div 
                      className={style["topic-question"]}
                      onClick={()=>{
                        if(chapters_topics_btn_value.current === "chapters"){
                          openQuizWithQuestionId({questionId: topicQuestionItem.question_id})
                        } else {
                          openQuizWithQuestionId({questionId: topicQuestionItem.question_id, topicId: currentTopicData.new_topic_id})
                        }
                      }}
                      >
                        <div className={style["topic-question-left"]}>
                          <h3>{topicQuestionItem.question_title}</h3>
                        </div>
                        <div className={style["topic-question-right"]}>
                          <aside style={{
                            backgroundColor: getQuestionStatusColor(topicQuestionItem.question_status),
                            boxShadow: addBoxShadowForQuestionStatus(topicQuestionItem.question_status)
                            }}>
                              <small className={style["global-tooltip"]}>{questionStatusNameMapping[topicQuestionItem.question_status]}</small>
                          </aside>
                        </div>
                      </div>
                    ))
                  :
                    <>
                      <div className={style["topic-question-loading"]}>
                        <img src={loadingsmall} alt="loading gif" />
                      </div>
                    </>
                }
              </div>
            </div>
          </>
        :
          null
      }
      </>
  )
}

  function switchTopicTypeUtility(topic_type){
    if(Object.keys(sharedUserInfo).length > 0 && sharedUserInfo.plan_type === "free" && sharedUserInfo.payment_history_count > 0){
      return;
    } else {
      localStorage.setItem("defaultTopicType", topic_type);
      setQuizFilterState([]);
      setCurrentTopicData({});
      quizFilterRef.current = [];
      selectedTopicTypeData.current = [];
      set_chapters_topics_btn(topic_type)
      chapters_topics_btn_value.current = topic_type;
      initializePage();
    }
  }
  function ChapterTopicToggleComponent(){ 
    return(
      <>
        <div className={style["chapters-topics-toggle-btn"]}>
          <article
            onClick={()=>{switchTopicTypeUtility("chapters")}}
            className={chapters_topics_btn === "chapters" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
          >
            Chapters
          </article>
          <article
            onClick={()=>{switchTopicTypeUtility("topics")}}
            className={chapters_topics_btn === "topics" ? `${style["chapters-topics-toggle-btn-selected"]}` : ""}
            style={
              Object.keys(sharedUserInfo).length > 0 &&
              sharedUserInfo.plan_type === "free" &&
              sharedUserInfo.payment_history_count > 0
              ?
                {
                  pointerEvents: "none",
                  opacity: "0.3",
                }
              :
                {}
            }
          >
            Topics
          </article>
        </div>
      </>
    )
  }

  function toggleShuffleModeUtility() {
    setEnableSuffleMode(!enableSuffleMode);
    enableSuffleModeRef.current = !enableSuffleModeRef.current;
  };
  function ShuffleModeToggleComponent(){ 
    return(
      <>
        <div onClick={(e)=>{toggleShuffleModeUtility()}} className={`${style["shuffle-btn-container"]}`}>
          <small className={style["global-tooltip"]} style={{top:"35px"}}>Get questions from your entire examspace</small>
          <span className={style["shuffle-btn-text"]}>shuffle mode</span>
          <label className={style["shuffle-switch"]}>
            <span style={{backgroundColor: enableSuffleMode ? "var(--dark-violet)" : "#e4e4e4" }} className={style["shuffle-slider"]}>
              <span style={{transform: enableSuffleMode ? "translateX(26px)" : "translateX(0px)" }} className={style["shuffle-slider-circle"]}></span>
            </span>
          </label>
        </div>
      </>
    )
  }


  function openQuizFilterUtility(){
    if(window_width < 1000){
      set_show_quiz_filter(true)
    } else{
      if(enableSuffleModeRef.current === false){
        set_show_quiz_filter(true)
      }
    }
  }
  function generateQuizFilterValues({defaultSelectionType, conceptAPIResponse=[]}){
    console.log('4 generateQuizFilterValues function called');
    return new  Promise((resolve, reject) => {
      let responseArray = [];
      if(chapters_topics_btn_value.current === "chapters"){
        responseArray = JSON.parse(JSON.stringify(selectedTopicTypeData.current));
        responseArray.forEach((resource) => {
          resource.selected = defaultSelectionType;
          resource.topic_list.forEach((topic)=>{
            if (topic.is_locked === false) {
              topic.selected = defaultSelectionType;
            }
          })
        });
      } else if (chapters_topics_btn_value.current === "topics"){
        responseArray = JSON.parse(JSON.stringify(selectedTopicTypeData.current));
        responseArray.forEach((topic) => {
          if(topic.is_locked === false){
            topic.selected = defaultSelectionType;
          }
        });
      }
      console.log('4.1 generateQuizFilterValues function responseArray:', responseArray);
      resolve(responseArray);
    });
  }
  function validateSavedQuizFilterState({updatedQuizFilterState=null}){
    return new Promise((resolve, reject) => {
      console.log('[5.1] validateSavedQuizFilterState function called')
      let localStorageValue = null;
      if(chapters_topics_btn_value.current === "chapters"){
        localStorageValue = JSON.parse(localStorage.getItem("savedQuizFilterChapters"));
      } else if(chapters_topics_btn_value.current === "topics"){
        localStorageValue = JSON.parse(localStorage.getItem("savedQuizFilterTopics"));
      }

      if (localStorageValue !== null) {
        console.log('[5.2] savedQuizFilterSelection value present in local storage')

        let savedQuizFilterValue = localStorageValue;
        console.log('[5.3] printing savedQuizFilterSelection from localStorage', savedQuizFilterValue)
        let savedQuizFilterTopicList = savedQuizFilterValue["quizFilterstate"];
        let savedQuizFilterExamspaceId = savedQuizFilterValue["examspace_id"];

        // check if saved examspaceId is same as current examspaceId
        if (savedQuizFilterExamspaceId === global_default_examspace_id.current){
          console.log('[5.4] saved examspaceId is same as current examspaceId')

          if(currentExamspaceDataRef.current.resources.length > 0){
            console.log('[5.5] - resource available in current examspace')

            if(chapters_topics_btn_value.current === "chapters"){
              // Check any documents are removed
              let resource_id_array1 = updatedQuizFilterState.map(item => item.resource_id);
              savedQuizFilterTopicList.forEach((resource) => {
                if (!resource_id_array1.includes(resource.resource_id)) {
                  console.log('[5.6] - resource deleted, so removing it from savedQuizFilterTopicList', resource.resource_id);
                  delete savedQuizFilterTopicList[resource.resource_id];
                }
              })
              // check any new documents are added
              let resource_id_array2 = savedQuizFilterTopicList.map(item => item.resource_id);
              updatedQuizFilterState.forEach((resource) => {
                if (!resource_id_array2.includes(resource.resource_id)) {
                  savedQuizFilterTopicList.push(resource);
                  console.log('[5.7] - new resource added by user, so adding it to savedQuizFilterTopicList', resource.resource_id)
                }
              });
            } else if (chapters_topics_btn_value.current === "topics"){
              // Check any topics are removed
              let topic_id_array1 = updatedQuizFilterState.map(item => item.topic_id);
              savedQuizFilterTopicList.forEach((topic) => {
                if(!topic_id_array1.includes(topic.topic_id)){
                  console.log('[5.6] - topic deleted, so removing it from savedQuizFilterTopicList', topic.topic_id);
                  delete savedQuizFilterTopicList[topic.topic_id]
                }
              });
              // Check any new topics are added
              let topic_id_array2 = savedQuizFilterTopicList.map(item => item.topic_id);
              updatedQuizFilterState.forEach((topic) => {
                if(!topic_id_array2.includes(topic.topic_id)){
                  console.log('[5.7] - new topic added by user, so adding it to savedQuizFilterTopicList', topic.topic_id);
                  savedQuizFilterTopicList.push(topic);
                }
              });
            }

            // check value is not empty
            if(savedQuizFilterTopicList.length > 0){
              console.log('[5.8] - updated savedQuizFilterTopicList returning data', savedQuizFilterTopicList)
              resolve(savedQuizFilterTopicList);
            } else {
              console.log('[5.8] - there is zero topic in savedQuizFilterTopicList', savedQuizFilterTopicList)
              resolve(false);
            }
          } else {
            console.log('[5.5] - all resources are removed from current examspace')
            resolve(false);
          }
        } else {
          console.log('[5.4] - saved examspace and current are not same, saved examspace_id:', savedQuizFilterExamspaceId)
          resolve(false);
        }
      } else {
        console.log('[5.2] savedQuizFilterSelection value not present in local storage')
        resolve(false);
      }
    });
  }  
  function initialQuizFilterSelection(){
    return new Promise(async (resolve, reject) => {
      console.log('[3.1] - initialQuizFilterSelection function called')
      let updatedQuizFilterState = await generateQuizFilterValues({defaultSelectionType: true});
      let result = await validateSavedQuizFilterState({updatedQuizFilterState: updatedQuizFilterState});
      if(result){
        console.log('[3.2] - saved quizFilterState in localStorage is available:', result)
        quizFilterRef.current = result;
        console.log('quizFilterRef.current - 1',quizFilterRef.current);
        setQuizFilterState(result);
        QuizFilterCounterCalculate()
        resolve(true);
      } else {
        console.log('[3.2] - saved quizFilterState in localStorage is not available',);
        updatedQuizFilterState = await generateQuizFilterValues({defaultSelectionType: true});
        console.log('[3.3] - removeing saved Quiz Filter Selection from local storage')
        if(chapters_topics_btn_value.current === "chapters"){
          localStorage.removeItem("savedQuizFilterChapters");
        } else if (chapters_topics_btn_value.current === "topics"){
          localStorage.removeItem("savedQuizFilterTopics");
        }
        quizFilterRef.current = updatedQuizFilterState;
        console.log('quizFilterRef.current - 2', quizFilterRef.current);
        setQuizFilterState(updatedQuizFilterState);
        QuizFilterCounterCalculate()
        resolve(true);
      }
    });
  }
  const handleSelectionChapters = ({type="", resourceId="", topicId = null, trigger=null, override=null}) => {
    console.log(`handleSelectionChapters function called params: type: ${type}, resourceId: ${resourceId}, topicId: ${topicId}, trigger: ${trigger}`);
  
      setQuizFilterState((prev) => {
        let updatedValue = undefined;
        let currentValue = prev
        if (type === "resource") {
          // Update the resource's selected status
          updatedValue =  currentValue.map(resource => {
            if (resource.resource_id === resourceId) {
                // Update the resource's selected status
                const updatedSelectedStatus = resource.selected === true ? false : true;
                const updatedResource = {
                  ...resource,
                  selected: override === null ? updatedSelectedStatus : override
                };
                // Update the selected status for all the topics in the resource
                updatedResource.topic_list = resource.topic_list.map(topic => ({
                  ...topic,
                  selected: topic.is_locked ? topic.selected : (override === null ? updatedSelectedStatus : override)
                }));
                return updatedResource;
            }
            return resource;
          });
          console.log('quiz filter selection, resource_id updated value', updatedValue);
        } else if (type === "topic" && topicId) {
          // Update the topic's selected status
          updatedValue =  currentValue.map(resource => {
            if (resource.resource_id === resourceId) {
              console.log('resource', resource);
              // find the requested topic and update its selected status
              resource.topic_list = resource.topic_list.map(topic => {
                if (topic.topic_id === topicId) {
                  const updatedSelectedStatus = topic.selected === true ? false : true;
                  topic.selected = override === null ? updatedSelectedStatus : override;
                  console.log('topic', topic);
                }
                return topic;
              });
              // check if any one topic related to this reosurce is selected.
              // if any one topic is selected, the resource is selected
              // if none of the topic is selected, the resource is not selected
              resource.selected = resource.topic_list.some(topic => topic.selected === true) ? true : false;
            }
            return resource;
          });     
          console.log('quiz filter selection, topic_id updated value', updatedValue);     
        }
        if(trigger === "ui"){
          console.log('value of quiz filter selection before storing in local storage', updatedValue)
          localStorage.setItem("savedQuizFilterChapters", JSON.stringify(
            {
              examspace_id: global_default_examspace_id.current,
              quizFilterstate: updatedValue
            }
          ));
        }
        quizFilterRef.current = updatedValue;
        QuizFilterCounterCalculate()
        return updatedValue;
      });
    };
  const handleSelectionTopics = ({topicId = "", trigger=null, override=null}) => {
      return new Promise((resolve, reject) => {
        console.log(`handleSelectionTopics function called params: topicId: ${topicId}, trigger: ${trigger}, override: ${override}`);
        setQuizFilterState((prev) => {
          let updatedValue = undefined;
          let currentValue = prev
          console.log('currentValue', currentValue);
          updatedValue = currentValue.map(topic => {
            if(topic.topic_id === topicId){
              // update the topic's selected status
              const updatedSelectedStatus = topic.selected === true ? false : true;
              const updatedTopic = {
                ...topic,
                selected: override === null ? updatedSelectedStatus : override
              }
              return updatedTopic;
            }
            return topic;
          })
          // let doesAnyOneTopicSelected = currentValue.some((topic) => topic.locked === false && topic.selected === true);
          // if (doesAnyOneTopicSelected){
          // } else {
          //   updatedValue = currentValue;
          //   console.log('since this is the last topic thats left selected, you cant unselected it.');
          // }
          if(trigger === "ui"){
            console.log('value of quiz filter selection before storing in local storage', updatedValue)
            localStorage.setItem("savedQuizFilterTopics", JSON.stringify(
              {
                examspace_id: global_default_examspace_id.current,
                quizFilterstate: updatedValue
              }
            ));
          }
          quizFilterRef.current = updatedValue;
          QuizFilterCounterCalculate()
          return updatedValue;
        });
        resolve(true);
      })
    };
    const toggleQuizFilterResource = (resourceId) => {
      if (enableSuffleModeRef.current === false){
        handleSelectionChapters({type:"resource", resourceId:resourceId, trigger:"ui"});
      }
    };
    const toggleQuizFilterTopic = ({topicId="", resourceId=""}) => {
      if (enableSuffleModeRef.current === false){
        handleSelectionChapters({type:"topic", resourceId:resourceId, topicId:topicId, trigger:"ui"});
      }
    };
    const toggleQuizFilterTopic2 = (topicId) => {
      if (enableSuffleModeRef.current === false){
        handleSelectionTopics({topicId:topicId, trigger:"ui"})
      }
    };
    function showGoUnlimitedBtn(text="Go unlimited"){
      return(
        <>
        <div onClick={()=>{navigate("/pushmode")}} className={style["go-unlimited-btn"]}>
        <img src={rocketIcon} alt="rocket icon" />
        <p>{text}</p>
        </div>
        </>
      )
    }
  function QuizFilterCounterIconComponent(){
    return(
      enableSuffleModeRef.current === false && show_quiz_filter === false && quizFilterCounter > 0 && current_question_content.length > 0 && question_available === true
      ?
        <>
          <div 
            className={style["quiz-filter-counter-icon"]}
            style={
              window_width < 1000
              ?
                {
                  left: "15px",
                  
                }
              :
                {
                  left: "19px",
                }
            }
          >
            <p className={style["quiz-filter-counter-icon-text"]}>{quizFilterCounter}</p>
          </div>
        </>
      :
        <></>
    )
  }
  function QuizFilterCounterCalculate(){
    console.log('QuizFilterCounterCalculate function called')
    if (chapters_topics_btn_value.current === "chapters"){
      // Check if there is at least one unselected topic across all resources
      // Returns true if any unlocked topic is not selected
      // Iterates through each resource and its topic list
      // Only considers topics that are not locked (is_locked === false)
      let isAnyOneTopicUnselected = quizFilterRef.current.some((resource) => {
        return resource.topic_list.some((topic) => {
          return topic.is_locked === false && topic.selected === false;
        });
      });

      if (isAnyOneTopicUnselected) {
        // If any one topic is unselected
        let selectedTopicsCount = 0;
        quizFilterRef.current.forEach((resource) => {
          resource.topic_list.forEach((topic) => {
            // Count topics that are both unlocked and selected
            if (topic.is_locked === false && topic.selected === true) {
              selectedTopicsCount++;
            }
          });
        });
        if(selectedTopicsCount > 99){
          setQuizFilterCounter(99);
        } else {
          setQuizFilterCounter(selectedTopicsCount);
        }
      } else {
        // If all topics are selected, set counter to 0
        setQuizFilterCounter(0); 
      }
    } else if (chapters_topics_btn_value.current === "topics"){
      let isAnyOneTopicUnselected = quizFilterRef.current.some((topic) => {
        return topic.is_locked === false && topic.selected === false;
      });
      if (isAnyOneTopicUnselected){
        let selectedTopicsCount = 0;
        quizFilterRef.current.forEach((topic) => {
          if (topic.is_locked === false && topic.selected === true){
            selectedTopicsCount++;
          }
        })
        if(selectedTopicsCount > 99){
          setQuizFilterCounter(99);
        } else {
          setQuizFilterCounter(selectedTopicsCount);
        }
      } else {
        setQuizFilterCounter(0);
      }
    }
  }
  function QuizFilterComponent(){ 
    return(
    <>
      <div className={`${style["quiz-filter-container"]}`}>
        <div  
          className={`${style["quiz-filter-btn"]} ${enableSuffleModeRef.current === false ? style["quiz-filter-btn-enable"] : style["quiz-filter-btn-disable"] }`}
          onClick={()=>{openQuizFilterUtility()}}
        >
          {
            window_width >= 1000
            ?
              <FilterIcon/>
            :
              show_quiz_filter === false
              ?
                <FilterIcon/>
              :
                <CloseBlackIcon/>
            }
          { window_width >= 1000 ? <span>Filter</span> : <></> }
          {QuizFilterCounterIconComponent()}
        </div>
        <div 
          onClick={(e)=>{set_show_quiz_filter(false)}}
          style={show_quiz_filter === true ? {display: "block"} : {display: "none"}}
          className={`${style["quiz-filter-backdrop"]}`}>
        </div>
        <div 
          className={`${style["quiz-filter-overlay-btn"]}`}
          onClick={()=>{set_show_quiz_filter(false)}}
          style={show_quiz_filter === true ? {display: "inline-flex"} : {display: "none"}}>
          {
              window_width >= 1000
              ?
                <FilterIcon/>
              :
                show_quiz_filter === false
                ?
                  <FilterIcon/>
                :
                  <CloseBlackIcon/>
              }
            { window_width >= 1000 ? <span>Filter</span> : <></> }
            {QuizFilterCounterIconComponent()}
        </div>
        <div  
          className={`${style["quiz-filter-content"]}`}
          style={show_quiz_filter === true ? {display: "block"} : {display: "none"}}
        >
          {/* filter header section */}
          <div className={`${style["quiz-filter-header"]}`}>
            <div className={`${style["quiz-filter-action-btns"]}`}>
              {/* {ChapterTopicToggleComponent()} */}
              {window_width < 1000 ? ShuffleModeToggleComponent() : null}
            </div>
            <div className={`${style["quiz-filter-title"]}`}>
              {
                chapters_topics_btn === "chapters"
                ?
                  "Chapters"
                :
                  "Topics"
              }
            </div>
          </div>
          <div
            className={enableSuffleMode === false
              ? `${style["quiz-filter-body-enable"]}`
              : `${style["quiz-filter-body-disable"]}`}
          >
            {quizFilterState && quizFilterState.length > 0 
              ?
                chapters_topics_btn === "chapters"
                ?
                  quizFilterState.map((resourceItem, index1) => {
                    return (
                      <>
                        <div style={{paddingBottom: "5px",}}>
                          <article className={`${style["resource-item"]}`}>
                            <label>
                              {
                                resourceItem["resource_title"].length > 30
                                ?
                                  <small className={style["global-tooltip"]}>{resourceItem["resource_title"]}</small>
                                :
                                  <></>
                              }
                              <input
                                id={`resource-${index1}`}
                                checked={resourceItem.selected}
                                onChange={() => toggleQuizFilterResource(resourceItem.resource_id)}
                                className={`${style["quiz-filter-checkbox"]}`}
                                type="checkbox"
                              />
                              <span>
                                {resourceItem["resource_title"].length > 30
                                  ? resourceItem["resource_title"].substring(0, 30) +
                                    "..."
                                  : resourceItem["resource_title"]}
                              </span>
                            </label>
                            <img
                              style={{ display: "block" }}
                              src={downArrowBlack}
                              alt="arrow"
                              onClick={(event) => {
                                let element = document.getElementById(`resource-topic-card-${resourceItem.resource_id}`);
                                console.log('element', element);
                                console.log('element.style.visibility before', element.style.display, element);
                                if (element.style.display === "none") {
                                  event.target.style.transform = "rotate(0deg)";
                                  element.style.display = "block";
                                  console.log('1')
                                } else {
                                  event.target.style.transform = "rotate(-90deg)";
                                  element.style.display = "none";
                                  console.log('2')
                                }
                                console.log('element.style.visibility after', element.style.display,element);
                              }}
                            />
                          </article>
                          {/* @note question filter html */}
                          <div id={`resource-topic-card-${resourceItem.resource_id}`}>
                            <section
                              style={{ marginLeft: "10px", marginTop: "10px", display: "block"}}
                              className={enableSuffleMode === false ? `${style["quiz-filter-body-enable"]}` : `${style["quiz-filter-body-disable"]}`}                              
                            >
                              {resourceItem.topic_list.length > 0 ? (
                                resourceItem.topic_list.map((topicItem) => {
                                  return (
                                    <>
                                      <aside 
                                        style={topicItem.is_locked === false ? { opacity: 1 } : {opacity: 0.5 }}
                                        className={`${style["concept-item"]}`}>
                                        <img
                                          id="quiz_filter_go_to_push_mode"
                                          onClick={() => {navigate("/pushmode");}}
                                          style={ topicItem.is_locked === false ? { display: "none" } : {display: "block", cursor: "pointer",}}
                                          src={lockIcon}
                                          alt="lock_icon"
                                        />
                                        <label
                                          style={topicItem.is_locked === false ? { pointerEvents: "auto" } : { pointerEvents: "none" }}
                                        >
                                          {
                                            topicItem["name"].length > 20
                                            ?
                                            <small className={style["global-tooltip"]}>{topicItem["name"]}</small>
                                            :
                                              <></>
                                          }                                          
                                          <input
                                            id={`${topicItem["id"]}`}
                                            className={`${style["quiz-filter-checkbox"]}`}
                                            type="checkbox"
                                            checked={topicItem.selected}
                                            onChange={() => toggleQuizFilterTopic({resourceId: resourceItem.resource_id, topicId: topicItem.topic_id})}                                           
                                          />
                                          <p>
                                            {topicItem["name"].length > 20
                                              ? topicItem["name"].substring(0, 20) +
                                                "..."
                                              : topicItem["name"]}
                                          </p>
                                        </label>
                                        <span>
                                          {`pages ${topicItem["start_page"]} - ${topicItem["end_page"]}`}
                                        </span>
                                      </aside>
                                    </>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </section>
                          </div>
                        </div>
                      </>
                    )
                  })
                :
                <>
                  {
                    // Topics UI
                    quizFilterState.map((topicItem, index1) => {
                      return(
                        topicItem.is_locked === false
                        ?
                          <>
                            <div className={`${style["new-topic-card"]}`}>
                              <label>
                                {
                                  topicItem["name"].length > 30
                                  ?
                                    <small className={style["global-tooltip"]}>{topicItem["name"]}</small>
                                  :
                                    <></>
                                }                              
                                <input type="checkbox"
                                  className={`${style["quiz-filter-checkbox"]}`}
                                  checked={topicItem.selected}
                                  onChange={() => toggleQuizFilterTopic2(topicItem.topic_id)}
                                />
                                <h2>
                                  {
                                    topicItem["name"].length > 30
                                    ? topicItem["name"].substring(0, 30) +
                                      "..."
                                    : topicItem["name"]
                                  }
                                </h2>
                              </label>
                            </div>
                          </>
                        :
                          <></>
                      )
                    })
                  }
                  {
                    Object.keys(sharedUserInfo).length > 0 &&
                    sharedUserInfo.plan_type === "free" &&
                    sharedUserInfo.payment_history_count <= 0
                    ?
                      <>
                        <div className={style["quiz-filter-push-mode-bottom"]}>
                          <p>See all Topics from your files</p>
                          {showGoUnlimitedBtn()}
                        </div>
                      </>
                    :
                      <></>
                  }
                </>
              : 
                <>
                  <div className={style["quiz-filter-loading"]}>
                    <img src={loadingsmall} alt="loading" />
                  </div>
                </>
            }         
          </div>
        </div>
      </div>
    </>
  )
}

function getCustomDateDifference({dateParams, status}) {
  try {
    const date = parseISO(dateParams);
    if (!isValid(date)) {
      return '';
    }
    const now = new Date();
    const millisecondsDifference = now - date;
    const daysDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60 * 24));
    if(status === "unanswered"){
      return 'New';
    } else {
      if (daysDifference > 1) {
          return `${daysDifference}d`;
      } else {
          return 'Today';
      }
    }
  } catch (error) {
    console.log("error in getCustomDateDifference function:", error)
    return "";
  }
}


function sendDendeEventsUtility({eventType, documentId, QuestionId}){
  if(eventType === "reread_requested"){
    SendDendeEvents({
      "event_type": "reread_requested",
      "document_id": documentId,
      "question_id": QuestionId,
    });
  } else if(eventType === "reread_completed"){
    SendDendeEvents({
      "event_type": "reread_completed",
      "document_id": documentId,
      "question_id": QuestionId,
    });
  }
}


  return (
    <>
      <section
        className="quiz-empty-state-container"
        style={ resource_available === false || question_available === false ? { display: "flex" } : { display: "none" }}
      >
        <div className="quiz-empty-state-content">
          {/* upload file UI */}
          <div
            className="quiz-empty-state-upload-file"
            style={
              resource_available === false && question_available === false
              // false
                ? { display: "block" }
                : { display: "none" }
            }>
              <p>Upload at least 1 document to see some questions here</p>
              <FileUploadComponent
                ref={fileUploadRef}
                onUploadSuccess={handleUploadSuccess}
                examspaceId={global_default_examspace_id.current}
              />
              <button onClick={triggerFileUpload}>
                <img src={uploadIcon} alt="upload icon"/>
                Upload a file
              </button>
          </div>
          {/* Generating questions UI */}
          <div
            className="quiz-empty-state-generate"
            style={
              resource_available === true && question_available === false
              // true
                ? { display: "block" }
                : { display: "none" }
            }
          >
          {
            sharedUserInfo && Object.keys(sharedUserInfo).length > 0
            ?
              <>
                <div 
                  style={sharedUserInfo.plan_type === "free" ? {top: "43px"} : window_width > 1000 ? {top: "20px"}: {top: "6px"}}
                  className={`${style["quiz-top-bar-container"]}`}
                  >
                    <div className={`${style["quiz-top-bar-content"]}`}>
                      {question_available === false ? QuizFilterComponent() : ""}
                    </div>
                </div>
              </>
            :
              <></>
          }
            <img src={loadingsmall} alt="loading" />
            <p>
              We are generating questions from your documents. Please hold on a
              few seconds
            </p>
          </div>
        </div>
      </section>

      <div
        style={resource_available === true && question_available === true ? { display: "block" } : { display: "none" }}
      >
        {
          sharedUserInfo && Object.keys(sharedUserInfo).length > 0
          ?
            <>
              {CurrentQuizTopicComponent()}
              <div 
                style={sharedUserInfo.plan_type === "free" ? {top: "43px"} : window_width > 1000 ? {top: "20px"}: {top: "6px"}}
                className={`${style["quiz-top-bar-container"]}`}
                >
                  <div className={`${style["quiz-top-bar-content"]}`}>
                    { window_width >= 1000 ? ShuffleModeToggleComponent() : "" }
                    {QuizFilterComponent()}
                  </div>
              </div>
            </>
          :
            <></>
        }

        <main
          ref={containerRef}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchMove={(e) => handleTouchMove(e)}
          className="mcq-new-container"
          style={{ opacity: allownext ? "0.2" : "" }}
        >
          <div className="mcq-new-wrapper"
            style={window_width < 1000 && sharedUserInfo.plan_type === "free" ? {marginTop: "115px"} : {marginTop: "100px"}}
          >
            {/* <div className="box" ></div> */}
            {current_question_content.length ? (
              <section className="mcq-new-question-area">
                <div className="mcq-new-question-heading">
                  <div className={`${style["quiz-question-info"]}`}>
                  <div 
                    style={{
                      backgroundColor: getQuestionStatusColor(current_question_content[0]["question_status"]),
                      boxShadow: addBoxShadowForQuestionStatus(current_question_content[0]["question_status"])
                    }}
                    className={`${style["quiz-question-info-indicator"]}`}>
                      <small className={style["global-tooltip"]}>{questionStatusNameMapping[current_question_content[0]["question_status"]]}</small>
                  </div>
                    <div className={`${style["quiz-question-info-text"]}`}>
                    <small className={style["global-tooltip"]}>Last viewed</small>
                      {getCustomDateDifference(
                        {
                          dateParams: current_question_content[0]["question_status_updated_at"],
                          status: current_question_content[0]["question_status"]
                        }
                      )
                      }
                    </div>
                  </div>
                  <div className={`${style["quiz-question-text"]}`}>
                    {current_question_content[0].question_content["question"]}
                  </div>
                </div>
                <article ref={quizOptionsContainer} className="mcq-new-options-container">
                  {current_question_content[0].question_content["options"] ? (
                    <>
                      {current_question_content[0].question_content[
                        "options"
                      ].map((item, quizOptionsIndex) => {
                        return (
                          <div
                            className="mcq-new-option-card"
                            id={`mcq-option-card-${quizOptionsIndex}`}
                            key={quizOptionsIndex}
                            style={
                              option_card_clickable
                                ? { pointerEvents: "auto" }
                                : { pointerEvents: "none" }
                            }
                            onClick={(e) => {
                              optionClickHandler(`mcq-option-card-${quizOptionsIndex}`, item);
                              set_quiz_option_clicked(true);
                              // set_show_answer_in_feedback(false);
                            }}
                          >
                            <div className="quiz-options-sequence">
                              <span>
                                {quizOptionsIndex === 0 ? "a" : ""}
                                {quizOptionsIndex === 1 ? "b" : ""}
                                {quizOptionsIndex === 2 ? "c" : ""}
                                {quizOptionsIndex === 3 ? "d" : ""}
                              </span>
                            </div>
                            <div className="quiz-options-text">
                              {item["text"]}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </article>
              </section>
            ) : null}
          </div>
        </main>
        {/* when a quiz in taken, then if user click any where in the screen move to next quiz exluding 
        (quiz feedback box at bottom, next quiz arrow at right and filters on top) */}
        <section 
          className={`${style["next-quiz-clickable-backdrop"]}`}
          onClick={() => validateNextQues()}
          style={{ display: allownext ? "" : "none" }}>
        </section>
        <section
          ref={progressBarContainer}
          className="mcq-progress-bar-container"
        >
          {/* <p
            id="quiz_answer_feedback_popup_open"
            onClick={() => {
              set_feedback_popup_options({
                open: true,
                type: `answer_${global_answer_type.current}`,
                question_id: current_question_content[0].question_id,
                question_title:
                  current_question_content[0]["question_content"]["question"],
              });
              transition_next_quiz.current = false;
            }}
            className="mcq-new-tips-feedback-icon quiz-hints-icon-func"
          >
            <img
              className="quiz-hints-icon-func"
              src={blueThumbsDown}
              alt="thumbs down icon"
            />
          </p> */}
          <div ref={progressBar} className="mcq-progress-bar"></div>
          <div className="mcq-progress-bar-content">
            <div
              style={
                show_answer_in_feedback === false
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <h3 ref={quiz_hints_title}>Quiz feedback</h3>
              <p ref={quiz_hints_content}>
                This option accurately states the number of missions ISRO has
                launched to the Moon and Mars as mentioned in the passage.
              </p>
              <div className="feedback-menu-container">
                <h4
                  ref={feedback_see_ans_btn}
                  className="quiz-feedback-btn feedback-btn-box"
                  onClick={() => {
                    set_show_answer_in_feedback(!show_answer_in_feedback);
                    progressBar.current.style.opacity = "0";
                  }}
                >
                  <img src={correctAnsIcon} alt="correct icon" />
                  <span>Correct answer</span>
                </h4>
                <h4
                  className="quiz-feedback-btn feedback-see-btn-box"
                  onClick={() => {
                    handleClickReread(current_question_content[0]);
                    sendDendeEventsUtility({
                        eventType: "reread_requested",
                        documentId: current_question_content[0].resource_id,
                        QuestionId: current_question_content[0].question_id
                    });
                  }}
                  id="quiz_page_read_again_button2"
                >
                  <img src={searchBlackIcon} alt="search icon" />
                  <span>Read again</span>
                </h4>
              </div>
            </div>
            <div
              style={
                show_answer_in_feedback === true
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <p className="feedback-see-ans-content">
                {current_question_content.length
                  ? current_question_content[0].question_content.options.map(
                      (item, index) => {
                        if (item.is_correct === true) {
                          return (
                            <>
                              <div className="feedback-see-ans-content-title">
                                <span>
                                  {index === 0 ? "a" : ""}
                                  {index === 1 ? "b" : ""}
                                  {index === 2 ? "c" : ""}
                                  {index === 3 ? "d" : ""}
                                </span>
                                <h5>
                                  {item.text}
                                </h5>
                              </div>
                              <h6>{item.explanation}</h6>
                            </>
                          );
                        }
                      }
                    )
                  : ""}
              </p>
              <div className="feedback-menu-container">
                <h4
                  className="quiz-feedback-btn feedback-btn-box"
                  onClick={() => {
                    set_show_answer_in_feedback(!show_answer_in_feedback);
                    progressBar.current.style.opacity = "1";
                  }}
                >
                  <span>Back to feedback</span>
                </h4>

                <h4
                  onClick={() => {
                    handleClickReread(current_question_content[0]);
                    sendDendeEventsUtility({
                        eventType: "reread_requested",
                        documentId: current_question_content[0].resource_id,
                        QuestionId: current_question_content[0].question_id
                    });
                  }}
                  className="quiz-feedback-btn feedback-see-btn-box"
                  id="quiz_page_read_again_button1"
                >
                  <img src={searchBlackIcon} alt="search icon" />
                  <span>Read again</span>
                </h4>
              </div>
            </div>
          </div>
        </section>
        <div ref={quiz_guide_icon}>
          <div
            id="quiz_next_question_mobile"
            onClick={() => validateNextQues()}
            className="nextQuizClickMob"
            style={{ display: allownext ? "" : "none" }}
          >
            {/* <div>
              <div className="arrorIcon">
                <img src={swipeImg} alt="thumbs down icon" />
              </div>
            </div> */}
          </div>
        </div>

        <div ref={quiz_guide_text}>
          {/* <div className="tabMenu" style={{ display: allownext ? "" : "none" }}>
            <div>
              <div className="text">
                <p>Use "Tab" to move forward</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <FeedbackPopup
        props={feedback_popup_options}
        nextQuiz={move_to_next_quiz}
      ></FeedbackPopup> */}
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      <ToastContainer />
      <div
        id="quiz_next_question"
        onClick={() => validateNextQues()}
        className="nextQuizClick"
        style={{ display: allownext ? "" : "none" }}
      >
        <div>
          <div className="arrorIcon">
            <AiOutlineUpCircle />
          </div>
        </div>
      </div>

      {/* PDF container */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        {" "}
      </Worker>
      <section 
        className={`${style["pdf-viewer-backdrop"]}`}
        style={{ display: pdfopen ? "" : "none" }}
        onClick={(e)=>{
          handleClosePdf();
          sendDendeEventsUtility({
            eventType: "reread_completed",
            documentId: current_question_content[0].resource_id,
            QuestionId: current_question_content[0].question_id
          });          
        }}
        >
  
      </section>
      <div ref={pdf_section_container} className="pdf-container-quiz">
        {getpdf ? (
          <div
            className="rpv-core__viewer"
            style={{
              // border: "1px solid rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: "5px",
              overflow: "auto",
              backgroundColor: "white",
              minHeight: "100vh",
            }}
          >
            <div className={"pdf_tools_container"}>
              {/* For back */}
              <div
                onClick={() => {
                  handleClosePdf();
                  sendDendeEventsUtility({
                      eventType: "reread_completed",
                      documentId: current_question_content[0].resource_id,
                      QuestionId: current_question_content[0].question_id
                  });
                }}
                className="pdf_tools_container_close"
              >
                {window.innerWidth > 600 ? (
                  <>
                    <XMarkIcon style={{ width: "22px", stroke: "black" }} />
                    <p>Close</p>
                  </>
                ) : (
                  <>
                    <ChevronLeftIcon className={"icon-left"} />
                    <p className={style["back-to-question"]}>
                      back to questions{" "}
                    </p>
                  </>
                )}
              </div>
              <div className="pdf_tools_container-menu">
                {/* For navigations */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <div>
                    <GoToPreviousPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={RenderGoToPageProps.onClick}
                        >
                          <ChevronUpIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToPreviousPage>
                  </div>
                  <div>
                    <GoToNextPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            background: "white",
                            // color: RenderGoToPageProps.isDisabled
                            //   ? "#96ccff"
                            //   : "#357edd",
                            border: "none",
                            // color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={RenderGoToPageProps.onClick}
                        >
                          <ChevronDownIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToNextPage>
                  </div>

                  <div style={{ padding: "0px 2px" }}>
                    <CurrentPageInput />
                  </div>

                  {/* ${RenderCurrentPageLabelProps.currentPage + 1} */}
                  <CurrentPageLabel>
                    {(RenderCurrentPageLabelProps) => (
                      <span>{`
                     of ${RenderCurrentPageLabelProps.numberOfPages}`}</span>
                    )}
                  </CurrentPageLabel>
                </div>

                {/* For zoom */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <ZoomOut>
                    {(RenderZoomOutProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomOutProps.onClick}
                      >
                        <MinusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomOut>
                  <ZoomIn>
                    {(RenderZoomInProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomInProps.onClick}
                      >
                        <PlusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomIn>
                </div>
              </div>
              <div></div>
            </div>

            <div
              // onTouchStart={handleTouchStartPDF}
              // onTouchMove={handleTouchMovePDF}
              style={{
                flex: 2,
                overflow: "hidden",
              }}
            >
              <Viewer
                initialPage={pagenumber}
                fileUrl={getpdf}
                plugins={[pageNavigationPluginInstance, zoomPluginInstance]}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
